import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamSingle } from "../containers";
import { useTranslation } from "react-i18next";

const PersonalDetails = () => {
    const { t, i18n } = useTranslation();
    return (
        <Fragment>
            <Seo title={t('board_of_director_title')} />
            <HeaderTwo />
            <Breadcrumb pageName="Board of Directors" bgImage="/images/miw/bar_006.jpg" />
            <TeamSingle />
            <FooterTwo />
            <ScrollTopBtn />
        </Fragment>
    )
};

export default PersonalDetails;
