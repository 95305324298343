import React, { Fragment } from 'react';
import { Seo, Breadcrumb, ScrollTopBtn } from '../components';
import {
  HeaderTwo,
  FooterTwo,
  TeamAds
} from '../containers';

const DigitalAdvertisingSolution = () => (
  <Fragment>
    <Seo title="General Information" />
    <HeaderTwo />
    <Breadcrumb pageName="Digital Advertising Solution" bgImage="images/miw/advertising-banner.jpg" />
    <TeamAds className="" />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default DigitalAdvertisingSolution;