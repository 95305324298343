import React, { Fragment } from 'react';
import {Seo, ScrollTopBtn, Breadcrumb} from '../components';
import {
  HeaderTwo, 
  HeroOne, 
  TeamPublications,
  FooterTwo,
} from '../containers';

const Publications = ()=> (
  <Fragment>
    <Seo title="Form 56-1 One Report" />
    <HeaderTwo/>
    <Breadcrumb pageName="Form 56-1 One Report" bgImage="images/miw/bar.jpg" />

    <TeamPublications className=""/>


    <FooterTwo/>
    <ScrollTopBtn/>
  </Fragment>
);

export default Publications;