// import React from "react";
// import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import AppRouters from "./routes/AppRouters";
import CookieConsent from "react-cookie-consent";
// import "./index.css";
import "./assets/fonts/exo/exo.css";
import "./assets/fonts/fira-sans/fira-sans.css";
import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fontawesome.css";
import "./assets/scss/style.scss";
import "moment/locale/th";

function App() {
  //  const { productId } = useParams();
  // const { location, hash, key } = useLocation();

  return (
    <BrowserRouter>
      <AppRouters />
      <CookieConsent
        buttonText="Accept"
        buttonStyle={{ background: "var(--theme-color)" }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
