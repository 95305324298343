import React from "react";

function SMSContact() {
  return (
    <div className="  bg-[url('/images/bgDown.jpg')]  bg-cover bg-center text-black flex justify-center  px-4 2xl:pt-36 2xl:mt-20 sm:mt-16 pt-8">
      <img
        src="/images/sms/img2.jpg"
        className="2xl:w-[550px] sm:w-[200px] w-[100px]  "
      />
      <div className="flex flex-col pl-4  2xl:gap-14 ">
        <div className="2xl:text-[120px] sm:text-[80px] text-[40px] font-[500] 2xl:mt-14 sm:mt-8 mb-4">
          Contact
        </div>
        <div className="flex gap-1 lg:mt-14 sm:mt-6 items-center">
          <img src="/images/logo/logo6.jpg" className="logo" />
          <a
            href="tel:+66 2530 0185-6"
            className="text-black hover:text-orange 2xl:text-[70px] 2xl:ml-4 sm: ml-2 lg:text-[40px] sm:text-[30px]  text-[15px] "
          >
            +66 2530 0185-6
          </a>
        </div>
        <div className=" flex gap-2 items-center pt-2">
          <img src="/images/logo/logo7.jpg" className="logo" />
          <a
            href="mailto:admin@cheesedigitnet.com"
            className="text-black hover:text-orange 2xl:text-[70px] 2xl:ml-4 sm: ml-2 lg:text-[40px] sm:text-[30px] text-[15px]"
          >
            admin@cheesedigitnet.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default SMSContact;
