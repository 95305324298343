import React, { Fragment, useEffect, useState } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamBoardDirectors } from "../containers";
import useGetExecutives from "../hooks/useGetExecutives";
import { TitleWrap } from "../components/Titles/Titles";
import { useTranslation } from "react-i18next";
const qs = require("qs");

const BoardDirectors = () => {
  const { executives, loading, getExecutives } = useGetExecutives();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const queries = qs.stringify(
      {
        filters: {
          Type: {
            $eq: "Board",
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    getExecutives(queries);
  }, []);

  return (
    <Fragment>
      <Seo title="Board of Directors" />
      <HeaderTwo />
      <Breadcrumb
        pageName="Board of Directors"
        bgImage="images/miw/bar_006.jpg"
      />
      <div className="bg-our-clients">
        <div className="container mb-100px">
          <TitleWrap className="text-center">
            <div class="d-flex justify-content-center pt-60px">
              <h1 class="title-line">{t("board_of_director_title")}</h1>
            </div>
          </TitleWrap>
          <div className="our-team-section">
            <div className="container">
              <div className="row">
                {!loading
                  ? executives?.data.map((element) => {
                      return (
                        <TeamBoardDirectors
                          data={element.attributes}
                          id={element.id}
                          key={element.id}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default BoardDirectors;
