import React from "react";
import { Link } from "react-router-dom";

function SMSHeader() {
  return (
    <div className="bg-[url('/images/bg1.jpg')]  bg-cover bg-center">
      <div className="flex flex-col lg:gap-6 sm:gap-2  px-14 text-start mx-auto pt-10 sm:px-40 lg:pt-12 lg:mx-0">
        <div className="flex flex-col  sm:gap-2 lg:gap-6">
          <div className=" leading-[0.8] text-yellow  tracking-widest  lg:text-[200px] sm:text-[140px] text-[70px] font-[400]">
            SMS
          </div>
          <div className=" text-white lg:text-[76px] sm:text-[55px] text-[28px] pb-6">
            MARKETING
          </div>
        </div>
        <div className="text-white lg:text-[60px] sm:text-[40px] text-[20px] lg:pt-8 font-thin ">
          บริการส่ง SMS
        </div>
        <div className=" leading-[0.8] pb-6 text-yellow lg:text-[75px] sm:text-[55px] text-[30px]">
          อัตราส่งสำเร็จสูงสุด
        </div>
        <div className="flex gap-6">
          <div className="text-white flex-col">
            <div className=" leading-[1.2] lg:text-[70px] sm:text-[50px]  text-[28px] font-bold">
              ถูกที่สุด
            </div>
            <div className="lg:text-[60px] sm:text-[45px] text-[20px] text-right">
              เพียง
            </div>
          </div>
          <div className="flex-col">
            <div className=" leading-[0.8] text-yellow lg:text-[190px] sm:text-[135px] text-[80px] font-bold">
              0.19
            </div>
            <div className="flex-col  text-white text-center sm:pt-6 pt-2 pb-4 ">
              <div className="leading-[1] lg:text-[40px] sm:text-[32px] text-[20px]">
                บาท / ข้อความ
              </div>
              <div className="lg:text-[25px] sm:text-[19px] text-[12px]">
                (คิดเงินเฉพาะที่ส่งสำเร็จ)
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col lg:text-[24px] sm:text-[15px] text-[10px] ">
          <div className="lg:ml-0 lg:flex-row lg:gap-6 lg:justify-start sm:gap-6 gap-2 sm:flex-row sm:justify-center flex flex-col items-center   ">
            <Link
              to={"/sms-solution/buy"}
              className="lg:w-[320px] w-[230px] text-center text-white bg-gradient-to-r from-[rgb(167,142,55)] to bg-yellow hover:bg-yellow_hv px-14 sm:py-2 lg:py-4 rounded-full cursor-pointer"
            >
              ติดต่อสั่งซื้อ
            </Link>

            <Link
              to={"/smssolution-details/contact-us"}
              className="lg:w-[320px] w-[230px] text-center text-white border border-yellow hover:bg-white hover:text-black px-4 sm:py-2 lg:py-4  rounded-full cursor-pointer "
            >
              ทดลองบริการส่ง SMS ฟรี
            </Link>
          </div>
          <div className="text-yellow py-3 pl-4 sm:pl-14 text-justify lg:pl-0 sm:text-justify lg:text-start lg:text-[25.5px]">
            ส่ง SMS Marketing, SMS OTP, SMS API ครบจบในที่เดียว
          </div>
        </div>
      </div>
    </div>
  );
}

export default SMSHeader;
