/** @format */

import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, TeamFour, CtaOne, AboutTwo, FooterTwo, HeaderOne, ServiceFour, ProcessTwo, TeamTwo, TestimonialThree, BlogFour, TeamGeneralInformation, TestimonialOne} from "../containers";

const GeneralInformation = () => (
    <Fragment>
        <Seo title="General Information" />
        <HeaderTwo />
        <Breadcrumb pageName="General Information" bgImage="images/miw/bar_016.jpg" />
        <TeamGeneralInformation className="" />
        <FooterTwo />
        <ScrollTopBtn />
    </Fragment>
);

export default GeneralInformation;
