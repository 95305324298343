/** @format */

import React, { useRef, useState, useEffect } from "react";
import { TitleWrap, SecTitle, SecSubTitle, SlideNavBtn, TestimonialBoxOne, ServiceBoxOne } from "../../components";

// Testimonial Data
import vision from "../../data/vision.json";

const TestimonialTwo = ({ ...restProps }) => {
    const sliderWrap = useRef();
    const [title, setTitle] = useState('ค่านิยม');
    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            const lang = localStorage.getItem('cheese_lang');
            if (lang === 'en') {
                setTitle('Core Value');
            } else {
                setTitle('ค่านิยม');
            }
        } else {
            setTitle('ค่านิยม');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            const lang = localStorage.getItem('cheese_lang');
            if (lang === 'en') {
                setTitle('Core Value');
            } else {
                setTitle('ค่านิยม');
            }
        } else {
            setTitle('ค่านิยม');
        }
    }, [title]);

    const settings = {
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div {...restProps}>
            <div className="container">
                <div className="d-flex justify-content-center">
                    <SecTitle className="h1 text-white">{title}</SecTitle>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-auto text-center text-lg-start">
                        <TitleWrap className="d-hidden">
                            <SecSubTitle>
                                <i className="fas fa-bring-forward" />
                                Our Best Review’s
                            </SecSubTitle>
                            <SecTitle className="h1">Customer’s Feedback</SecTitle>
                        </TitleWrap>
                    </div>
                </div>
                <div ref={sliderWrap} className="row" {...settings}>
                    {vision.map((item, index) => (
                        <div className="col-lg-4">
                            <div key={index}>
                                <TestimonialBoxOne image={item.image} icon={item.icon} title={item.title} text={`“${item.text.slice(0, 100)}”`} name={item.name} designation={item.designation} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialTwo;
