/** @format */

import React, { useRef } from "react";
import { Button, FormThree, InfoMedia, ProgressOne, SecSubTitle, SecTitle, TitleWrap } from "../../components";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TeamShareholdingDirectors = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('shareholding_directors_executives_title')}</h1>
          </div>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-12 mb-30">
            <table className="table-greenux">
              <thead className="thead-greenux">
                <tr>
                  <th style={{ width: "5%" }} className="text-center text-white">
                    {t('number_order')}
                  </th>
                  <th className="text-center text-white">{t('list')}</th>
                  <th style={{ width: "10%" }} className="text-center text-white">
                    {t('number_of_list_shares_held_dec')}
                  </th>
                  <th style={{ width: "10%" }} className="text-center text-white">
                    {t('number_of_list_shares_held_feb')}
                  </th>
                  <th style={{ width: "10%" }} className="text-center text-white">
                    {t('changes_increase')}
                  </th>
                  <th style={{ width: "10%" }} className="text-center text-white">
                    {t('proportion_shareholding')}
                  </th>
                  <th style={{ width: "10%" }} className="text-center text-white">
                    {t('appointment_date')}
                  </th>
                </tr>
              </thead>

              <tbody>
                {/* <tr>
                  <td rowSpan={2} className="text-center">
                    1.
                  </td>
                  <td>{t('sopawadee_lertmanaschai')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '31/10/2565'
                      : moment('31/10/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr> */}
                {/* <tr>
                  <td rowSpan={2} className="text-center">
                    2.
                  </td>
                  <td>{t('sarayut_nathaphan')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '31/10/2565'
                      : moment('31/10/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr> */}
                {/* <tr>
                  <td rowSpan={2} className="text-center">
                    3.
                  </td>
                  <td>{t('teanchai_keeraswangporn')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '10/08/2565'
                      : moment('10/08/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr> */}
                <tr>
                  <td rowSpan={2} className="text-center">
                    1.
                  </td>
                  <td>{t('phuriphat_pitaganan')}</td>
                  <td className="text-end">130,001</td>
                  <td className="text-end">130,001</td>
                  <td className="text-end">-</td>
                  <td className="text-end">26.00</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '18/05/2548'
                      : moment('18/05/2548', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td rowSpan={2} className="text-center">
                    2.
                  </td>
                  <td>{t('chouvanit_wattanasap')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '31/01/2565'
                      : moment('31/01/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                {/* <tr>
                  <td rowSpan={2} className="text-center">
                    6.
                  </td>
                  <td>{t('pongthep_vichaikul')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '31/01/2565'
                      : moment('31/01/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td rowSpan={2} className="text-center">
                    7.
                  </td>
                  <td>{t('kitti_phuathavornskul')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '23/02/2566'
                      : moment('23/02/2566', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr> */}
                <tr>
                  <td rowSpan={2} className="text-center">
                    3.
                  </td>
                  <td>{t('wattana_lawattanatrakul')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '01/12/2565'
                      : moment('01/12/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td rowSpan={2} className="text-center">
                    4.
                  </td>
                  <td>{t('ornkanya_boonyanuntakul')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '16/12/2565'
                      : moment('16/12/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td rowSpan={2} className="text-center">
                    5.
                  </td>
                  <td>{t('rutchawin_vedchasub')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">
                    {i18n.language === 'th' ? '15/08/2565'
                      : moment('15/08/2565', 'DD/MM/YYYY').subtract(543, 'year').format('DD/MM/YYYY')}
                  </td>
                </tr>
                <tr>
                  <td>{t('spouse')}</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamShareholdingDirectors;
