import React, { Fragment, useState } from "react";
import {
  Seo,
  Breadcrumb,
  ScrollTopBtn,
  Breadcrumb_Bannermodify,
} from "../components";
import { HeaderTwo, FooterTwo, TeamSMSSolutionDetails } from "../containers";
import { useTranslation } from "react-i18next";
import SMSMarketing from "../components/SMSMarketing/SMSMarketing";
import Contact from "./Contact";
import SMSContact from "../components/SMSMarketing/SMSContact";
import SMSHeader from "../components/SMSMarketing/SMSHeader";
// import "../index.css";

const SMSSolutionDetails = () => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <Seo title={t("board_of_director_title")} />
      <HeaderTwo />
      
      {/* <Breadcrumb pageName="Board of Directors" bgImage="/images/miw/bar_006.jpg" /> */}
      {/* <Breadcrumb pageName="SMS Solution" bgImage="/images/miw/bar_014.jpg" /> */}

      {/* Old */}
      {/* <Breadcrumb_Bannermodify
        pageName="SMS Solution"
        bgImage="images/sms/SMS-08.jpg"
      /> */}
      {/* Old */}

      <SMSHeader />
      <TeamSMSSolutionDetails />
      {/* <TeamSingle /> */}
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default SMSSolutionDetails;
