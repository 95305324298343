import { Button } from 'bootstrap';
import React from 'react';
import {Link} from 'react-router-dom';

const IconLinkImg = ({iconlang, title, children, className})=> (
  <div className={`icon-link ${className || ''}`}>
    <i className={iconlang}/> {title && <span className="ps-1 social-title me-1">{title}</span>}
    {children}
  </div>
);

IconLinkImg.Item = ({path, image, className})=> (
  <Link className="p-0 m-0" to={path || '#'}><button type="button" className={className}>{image}</button></Link>
);

export default IconLinkImg;