import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamContactUs } from "../containers";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <Fragment>
      <Seo title={t("contact_us")} />
      <HeaderTwo />
      <Breadcrumb pageName="Contact Us" bgImage="images/miw/bar_010.jpg" />
      <TeamContactUs contactType="contact_us" className="" />
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ContactUs;
