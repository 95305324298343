import React, { useRef, useEffect, useState } from "react";
import { InfoMediaDownloadFinancial, TitleWrap } from "../../components";

const TeamPolicy = (props) => {
  const contactArea = useRef(null);
  const [lang, setLang] = useState();
  const boardChaters = props.data?.BoardChaters;
  const policy = props.data?.Policy;


  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  }, []);


  return (
    <div>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{(lang === 'th' ? 'กฎบัตรคณะกรรมการและนโยบายสำคัญ' : 'Board Charters and Corporate Governance Policy')}</h1>
          </div>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-6 mb-30">
            <div className="project-box">
              {(boardChaters?.map((element, index) => {
                return <InfoMediaDownloadFinancial file={element.File} key={element.id} icon="fas fa-file" info={lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'} />
              }))}
            </div>
          </div>

          <div className="col-lg-6 mb-30">
            <div className="project-box">
              {(policy?.map((element, index) => {
                return <InfoMediaDownloadFinancial file={element.File} key={element.id} icon="fas fa-file" info={lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'} />
              }))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPolicy;
