import React, { useRef, useEffect, useState } from "react";
import { TitleWrap } from "../../components";
import { useTranslation } from "react-i18next";

const TeamShareholderStructure = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();
  const [shareHolders, setShareHolders] = useState([]);
  const [lang, setLang] = useState();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }

    setShareHolders([
      {
        titleTh: 'บริษัท เอ็นซีแอล อินเตอร์เนชั่นแนล โลจิสติกส์ จำกัด (มหาชน)',
        titleEn: 'NCL International Logistics Public Company Limited',
        numberOfShares: '125,000',
        percent: '25.00',
      },
      {
        titleTh: 'บริษัท บี แอนด์ โอ โฮลดิ้ง จำกัด',
        titleEn: 'B&O Holding Company Limited',
        numberOfShares: '244,999',
        percent: '49.00',
      },
      {
        titleTh: 'นายภูริภัทร์ ปิตกานันท์',
        titleEn: 'Mr. Phuriphat Pitaganan',
        numberOfShares: '130,001',
        percent: '26.00',
      }
    ]);
  }, [])

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('shareholder_title')}</h1>
          </div>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-12">
            {/* <img src="images/miw/organization-chart2.jpg" alt="team-member" /> */}
            <table className="table-greenux">
              <thead className="thead-greenux">
                <tr>
                  <th colSpan={2} className="text-center text-white">
                    {t('shareholder_free_float')} {lang === 'th' ? '28/02/2566' : '28/02/2023'}
                  </th>
                  <th colSpan={2} className="text-center text-white">
                    {t('shareholder_overview')} {lang === 'th' ? '28/02/2566' : '28/02/2023'}  {t('shareholder_rights_type')} : XM
                  </th>
                </tr>
              </thead>
              <thead className="thead-blueux">
                <tr>
                  <th className="text-white" style={{ width: "35%" }}>{t('shareholder_minor')}</th>
                  <th className="text-white" style={{ width: "15%" }}>-</th>
                  <th className="text-white" style={{ width: "35%" }}>{t('shareholder_total')}</th>
                  <th className="text-white" style={{ width: "15%" }}>-</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('shareholder_shares')}</td>
                  <td>-</td>
                  <td>{t('shareholder_scripless_holding')}</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td colSpan={4}>{t('shareholder_remark')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-12">
            {/* <img src="images/miw/organization-chart2.jpg" alt="team-member" /> */}
            <table className="table-greenux">
              <thead className="thead-greenux">
                <tr>
                  <th className="text-center text-white" style={{ width: "10%" }}>{t('shareholder_rank')}</th>
                  <th className="text-center text-white" style={{ width: "50%" }}>{t('shareholder_shareholders')}</th>
                  <th className="text-center text-white" style={{ width: "20%" }}>{t('shareholder_number_of_shares')}</th>
                  <th className="text-center text-white" style={{ width: "20%" }}>%</th>
                </tr>
              </thead>

              <tbody>
                {shareHolders.map((share, index) => {
                  return <tr>
                    <td className="text-center">{index + 1}</td>
                    <td>{lang === 'th' ? share.titleTh : share.titleEn}</td>
                    <td className="text-end">{share.numberOfShares}</td>
                    <td className="text-end">{share.percent}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamShareholderStructure;
