import React from "react";
import { baseUrl } from "../../services/Environment";
import { Link } from "react-router-dom";

const InfoMediaDownloadFinancial = ({ number, image, icon, title, info, className, file }) => (
  <div className={`info-media ${className || ""}`}>
    <div className="media-body text-20 ms-2">{info}</div>
    <div className="me-2"><Link to={file ? `${baseUrl}${file?.data.attributes?.url}` : '#'}> <img style={{width:"25px !important"}} className="icon-dl" src="images/miw/download.png" /></Link></div>
  </div>
);

export default InfoMediaDownloadFinancial;
