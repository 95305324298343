import React from "react";

const InfoMediaInvestor = ({number, image, icon, title, info, downloadPdfOne, downloadPdfTwo, downloadPdfThree, downloadPdfFour, downloadMp3One, downloadMp3Two, downloadMp3Three, downloadMp3Four, className}) => (
  <div className={`info-media ${className || ""}`}>
    <div className="d-flex flex-column">
      <div className="mb-2">{info}</div>
      <div className="mb-2">
        <div className="d-flex flex-row align-items-center">
          <div className="me-4">
            <img className="icon-investor" src="images/miw/icon-investor-pdf.png" />
          </div>
          <div className="me-4 d-investor">{downloadPdfOne}</div>
          <div className="me-4 d-investor">{downloadPdfTwo}</div>
          <div className="me-4 d-investor">{downloadPdfThree}</div>
          <div className="me-4 d-investor">{downloadPdfFour}</div>
        </div>
      </div>
      <div className="">
        <div className="d-flex flex-row align-items-center">
          <div className="me-4">
            <img className="icon-investor" src="images/miw/icon-investor-mp3.png" />
          </div>
          <div className="me-4 d-investor">{downloadMp3One}</div>
          <div className="me-4 d-investor">{downloadMp3Two}</div>
          <div className="me-4 d-investor">{downloadMp3Three}</div>
          <div className="me-4 d-investor">{downloadMp3Four}</div>
        </div>
      </div>
    </div>
  </div>
);

export default InfoMediaInvestor;
