/** @format */

import React, {useRef} from "react";
import {Button, FormThree, InfoMedia, ProgressOne, SecSubTitle, SecTitle, TitleWrap} from "../../components";

const TeamOurClients = ({...restProps}) => {
  const contactArea = useRef(null);

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  return (
    <div {...restProps}>
      <div className="container pb-100px">
        {/* <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">Our Clients</h1>
          </div>
        </TitleWrap> */}
        <div className="mb-30">
          <img src="images/miw/Clients-1.jpg" alt="team-member" />
        </div>
      </div>
    </div>
  );
};

export default TeamOurClients;
