/** @format */

import React, { useRef, useState, useEffect } from "react";
import {
  TitleWrap,
  SecTitle,
  SecSubTitle,
  SlideNavBtn,
  TestimonialBoxOne,
  ServiceBoxOne,
  TestimonialBoxOneNew,
} from "../../components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Testimonial Data
import testimonialData from "../../data/testimonial.json";

const TestimonialOne = ({ ...restProps }) => {
  gsap.registerPlugin(ScrollTrigger);
  const sliderWrap = useRef();
  const [title, setTitle] = useState("Why Choose Cheese?");
  const [lang, setLang] = useState();

  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      if (lang === "en") {
        setTitle("Why Choose Cheese?");
      } else {
        setTitle("Why Choose Cheese?");
      }
    } else {
      setTitle("Why Choose Cheese?");
      setLang("th");
    }
  });

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      if (lang === "en") {
        setTitle("Why Choose Cheese?");
      } else {
        setTitle("Why Choose Cheese?");
      }
    } else {
      setTitle("Why Choose Cheese?");
      setLang("th");
    }

    gsap.from(".why-section", {
      scrollTrigger: ".why-section",
      y: 200,
      duration: 1,
      transformOrigin: "bottom top",
      ease: "none",
    });
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div {...restProps}>
      <div className="container">
        <div className="d-flex justify-content-center">
          <SecTitle className="h1 text-white">{title}</SecTitle>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-auto text-center text-lg-start">
            <TitleWrap className="d-hidden">
              <SecSubTitle>
                <i className="fas fa-bring-forward" />
                Our Best Review’s
              </SecSubTitle>
              <SecTitle className="h1">Customer’s Feedback</SecTitle>
            </TitleWrap>
          </div>
        </div>
        <div ref={sliderWrap} className="row why-section" {...settings}>
          {testimonialData?.map((item, index) => (
            <div className="col-lg-4">
              <div key={index}>
                <TestimonialBoxOneNew
                  image={item.image}
                  icon={item.icon}
                  title={item.title}
                  text={`“${
                    lang === "th"
                      ? item.text_th.slice(0, 200)
                      : item.text_en.slice(0, 200)
                  }”`}
                  name={item.name}
                  designation={item.designation}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialOne;
