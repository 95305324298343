import React from "react";

const InfoMediaDownloadBook = ({number, image, imagePlayOne, imagePlayTwo, imageDownloadOne, imageDownloadTwo,  icon, titleOne, titleTwo, className}) => (
  <div className={`info-media ${className || ""}`}>
    {/*  <div className="media-icon">
      {image && <img src={image} alt="infoIcon"/>}
      {icon && <i className={icon}/>}      
      {number && number}      
    </div>
    <div className="media-body">
      {title && <span className="media-label">{title}</span>}
      {info && <div className="media-link">{info}</div>}
    </div> */}
    <div className="d-flex flex-row info-bookcover">
      <div className="w-50 hover01">{image && <figure><img src={image} alt="infoIcon" /></figure>}</div>
      <div className="w-50 d-flex align-items-center">
        <div className="info-book d-flex flex-column p-4">
          <div className="info-book-border">
            <div className="">{titleOne && <span className="info-book-txt">{titleOne}</span>}</div>
            <div>{imagePlayOne && <img src={imagePlayOne} className="icon-plc"/>} {imageDownloadOne && <img src={imageDownloadOne} className="icon-plc"/>} </div>
          </div>
          <div className="info-book-border">
            <div className="pt-2">{titleTwo && <span className="info-book-txt">{titleTwo}</span>}</div>
            <div>{imagePlayTwo && <img src={imagePlayTwo} className="icon-plc"/>} {imageDownloadTwo && <img src={imageDownloadTwo} className="icon-plc"/>} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InfoMediaDownloadBook;
