import React, { useEffect, useRef, useState } from "react";
import Table from "./Table";
import SMSLogo from "./SMSLogo";
import { Link } from "react-router-dom";
import SMSContact from "./SMSContact";
import { useParams } from "react-router-dom";
// import "../../index.css";
// import "../../custom.css";

function SMSMarketing() {
  const [lang, setLang] = useState();
  const { id } = useParams();
  // const [cssImported, setCssImported] = useState(false);

  // const location = window.location;
  // // console.log('location',location.pathname)
  // const [isHomePage, setIsHomePage] = useState(false);

  // useEffect(() => {
  //   // Check if the current path is the home page ("/")
  //   setIsHomePage(location.pathname === "/sms-solution");
  // }, []);

  // useEffect(() => {
  //   // Conditionally import index.css only when on the home page
  //   if (isHomePage) {
  //     console.log("ddddddddddddddddd", isHomePage);
  //     // import("../../../src/index.css");
  //     import("../../../src/index.css").then(() => {
  //       setCssImported(true);
  //     });
  //   } else {
  //     setCssImported(false);
  //   }
  // }, [isHomePage, cssImported]);

  // useEffect(() => {
  //   // Create a style element for index.css
  //   const styleElement = document.createElement("style");
  //   styleElement.id = "index-css";

  //   // Check if it's the home page, import or add the index.css content to DOM
  //   if (isHomePage) {
  //     import("../../../src/index.css").then((content) => {
  //       styleElement.innerHTML = content.default;
  //       document.head.appendChild(styleElement);
  //     });
  //   } else {
  //     // If it's not the home page, remove the style element
  //     const existingStyleElement = document.getElementById("index-css");
  //     if (existingStyleElement) {
  //       existingStyleElement.remove();
  //     }
  //   }
  // }, [isHomePage, cssImported]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
    scrollToElement(id);
    console.log("id", id);
  }, []);

  return (
    <div className=" mx-auto">
      {/* ------------ ติดต่อสั่งซื้อ + ค่าบริการ ------------- */}

      <div id={id} className="bg-[url('/images/bgUp.jpg')]  bg-cover bg-center">
        <div className="flex justify-center sm:gap-12 mx-4 gap-2 ">
          <div className=" 2xl:pt-20 sm:pt-14 sm:pl-4 pt-6 flex flex-col">
            <div className="flex items-center">
              <img
                src="/images/sms/img1.jpg"
                className="2xl:hidden md:w-[300px] sm:w-[230px] p-4 sm:h-full   h-[220px]"
              />
              <h1 className="leading-[1.3] 2xl:text-8xl sm:text-5xl text-3xl text-black font-[500] pb-2">
                SMS{" "}
                <span className="text-yellow font-extralight 2xl:text-9xl sm:text-7xl text-4xl 2xl:inline-block hidden">
                  Marketing
                </span>
                <br />
                <h1 className="text-yellow font-extralight 2xl:hidden sm:text-7xl text-4xl">
                  Marketing{" "}
                </h1>
              </h1>
            </div>

            <div className="flex flex-col gap-1 2xl:mt-4 sm:mt-2">
              <SMSLogo>
                <img src="/images/logo/logo1.jpg" alt="logo" className="logo" />
                <h2 className="logo ">คิดเงินเฉพาะที่ส่งสำเร็จ</h2>
              </SMSLogo>
              <SMSLogo>
                <img src="/images/logo/logo2.jpg" className="logo" />
                <h2 className="logo text-black">เช็กสถานะการส่งได้ทันที</h2>
              </SMSLogo>
              <SMSLogo>
                <img src="/images/logo/logo3.jpg" className="logo" />
                <h2 className="logo">
                  ส่ง SMS ได้เอง ผ่าน Dashboard <br /> ที่ใช้งานง่าย สะดวก{" "}
                </h2>
              </SMSLogo>
              <SMSLogo>
                <img src="/images/logo/logo4.jpg" className="logo" />
                <h2 className="logo text-black">
                  มีทีม Support มืออาชีพพร้อมให้คำปรึกษา
                </h2>
              </SMSLogo>
              <SMSLogo>
                <img src="/images/logo/logo5.jpg" className="logo" />
                <h2 className="logo">
                  ส่งข้อความได้สะดวก รวดเร็ว <br /> ผ่านระบบ API
                </h2>
              </SMSLogo>
            </div>
          </div>
          <img
            src="/images/sms/img1.jpg"
            className="2xl:w-[550px] md:w-[300px] sm:w-[230px] hidden 2xl:block sm:h-full lg:pt-48   sm:pt-32  h-[220px] pt-16 "
          />
        </div>

        <div className="relative container1 mx-auto  sm:px-6 px-0 ">
          <img
            src="/images/sms/star.jpg"
            className="2xl:w-20 md:w-10 mobile:hidden hidden  absolute 2xl:left-[-70px] 2xl:top-[345px] md:top-[185px]  md:block lg:hidden 2xl:inline"
          />
          {/* <div className="absolute shadow-[0px_0px_20px_2px_rgba(235,188,20,0.82)] border lg:w-[1600px] lg:h-[80px] sm:w-[730px] sm:h-[50px] w-[350px] h-[23px] lg:left-[170px] lg:top-[350px] sm:left-[35px] sm:top-[190px] left-[20px] top-[100px] ring-2 ring-[#fdde83]  "></div> */}
          <h1 className="2xl:text-[80px] text-center sm:text-[50px] text-[25px] text-black mx-auto  font-[500] lg:py-6 sm:pb-4 mt-4">
            ค่าบริการ
          </h1>
          <Table />
        </div>
      </div>

      {/*------------- SMS Services ----------------*/}
      <div className="mx-auto container1">
        <h1 className="title-line lg:text-[70px] sm:text-[45px] text-[25px] leading-[0.8] text-black mx-auto lg:mt-16 sm:mt-10 pt-6 pb-2">
          SMS Services
        </h1>

        <div className="mx-auto grid 2xl:grid-cols-4 grid-cols-2  2xl:min-w-[100%] 2xl:gap-24 sm:gap-14 justify-center px-2 sm:p-6">
          <div className="SMSContent">
            <img
              src="/images/logo/OTP-05_0.jpg"
              className="SMSServices "
              alt="team-member"
            />
            <h2 className="SMSheader text-black">SMS OTP</h2>
            <p className=" Services text-black">
              การยืนยันตัวตนหรือตรวจสอบสิทธิ์ผ่านระบบ SMS
            </p>
            <Link to="/otp-service">รายละเอียดเพิ่มเติม</Link>
          </div>

          <div className="SMSContent">
            <img
              src="/images/logo/MMS_0.png"
              className="SMSServices"
              alt="team-member"
            />
            <h2 className="SMSheader text-black">E-COUPON</h2>
            <p className="Services text-black">
              คูปองอิเล็กทรอนิกส์ หรือ คูปองดิจิทัล
              คูปองที่คุณสามารถซื้อและนำไปแลกสินค้าหรือบริการจากร้านค้าได้โดยตรง
            </p>
            <Link to={"/smssolution-details/contact-us"}>
              รายละเอียดเพิ่มเติม
            </Link>
          </div>
          <div className="SMSContent">
            <img
              src="/images/logo/2 WAY-06_0.png"
              className="SMSServices"
              alt="team-member"
            />

            <h2 className="SMSheader text-black">
              SMS 2Way/<span>USSD</span>
            </h2>
            <p className="Services text-black">
              ระบบสำหรับจัดแคมเปญทางการตลาด ที่ลูกค้าของคุณสามารถตอบกลับ SMS
              เพื่อร่วมสนุกได้ทันที
            </p>
            <Link to={"/smssolution-details/contact-us"}>
              รายละเอียดเพิ่มเติม
            </Link>
          </div>
          <div className="SMSContent">
            <img
              src="/images/logo/Location-04_0.png"
              className="SMSServices"
              alt="team-member"
            />
            <h2 className="SMSheader text-black">
              LBS<br></br>
              <span className="SMSheader">(Location Base SMS)</span>
            </h2>
            <p className="Services text-black">
              ส่ง SMS โฆษณา ประชาสัมพันธ์แบบเจาะจงพื้นที่
            </p>
            <Link to={"/smssolution-details/contact-us"}>
              รายละเอียดเพิ่มเติม
            </Link>
          </div>
        </div>
      </div>

      <div id="contact">
        <SMSContact />
      </div>
    </div>
  );
}

export default SMSMarketing;
