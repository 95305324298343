import React, { Fragment, useEffect } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamCompanyevents_title_Health_Check_up_Program_2024,BlogThree,BlogNews_Events } from "../containers";
// import useGetExecutives from '../hooks/useGetExecutives';
import { TitleWrap } from '../components/Titles/Titles'
import { useTranslation } from "react-i18next";
const qs = require('qs');

const BlogGrid = () => {
    // const { executives, loading, getExecutives } = useGetExecutives();
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     const queries = qs.stringify({
    //         filters: {
    //             Type: {
    //                 $eq: 'Executive',
    //             },
    //         },
    //     }, {
    //         encodeValuesOnly: true, // prettify URL
    //     });
    //     getExecutives(queries);
    // }, []);

    return (
        // <Fragment>
        //     <Seo title="Company Events" />
        //     <HeaderTwo />
        //     <Breadcrumb pageName="Company Events" bgImage="images/miw/bar_006.jpg" />
        //     <div className="bg-our-clients">
        //         <div className="container">
        //             <TitleWrap className="text-center">
        //                 <div class="d-flex justify-content-center pt-60px">
        //                     {/* <h1 class="title-line">{t('companyevents_title_Health_Check_up_Program_2024')}</h1> */}
        //                     <h1 class="title-line">{t('companyevents_title_Health_Check_2024')}</h1>
        //                 </div>
        //             </TitleWrap>
        //             <div className="our-team-section">
        //                 <div className="row mb-4">
        //                     <div className="col-12">
        //                         <div className="d-flex flex-row">
        //                             <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
        //                             <div className="p-2 team-it-title">{t('companyevents_title_Health_Check_up_Program_2024')}</div>
        //                         </div>
        //                         <div className="d-flex flex-row">
        //                             <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
        //                             <div className="p-2 text-d-vision lh-36 text-black">{t('companyevents_detail_Health_Check_up_Program_2024')}
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="container">
        //                     <div className="row">
        //                           <TeamCompanyevents_title_Health_Check_up_Program_2024 className="" />
        //                         {/* {(!loading) ? executives?.data.map((element) => {
        //                             return <TeamExecutives data={element.attributes} id={element.id} key={element.id} />
        //                         }) : null} */}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <FooterTwo />
        //     <ScrollTopBtn />
        // </Fragment>
          <Fragment>
            <Seo title="Company Activities" />
            <HeaderTwo />
            <Breadcrumb pageName="Company Activities" bgImage="images/events/events.jpg" />

            <div className="bg-our-clients">
                <div className="container">
                    <TitleWrap className="text-center">
                        <div class="d-flex justify-content-center pt-60px">
                            {/* <h1 class="title-line">{t('companyevents_title_Health_Check_up_Program_2024')}</h1> */}
                            <h1 class="title-line">{t('companyevents_title')}</h1>
                        </div>
                    </TitleWrap>
                    <div className="our-team-section">
                        <BlogNews_Events className="space-top space-extra-bottom"/>
                    </div>
                </div>
            </div>
            <FooterTwo/>
            <ScrollTopBtn/>
        </Fragment>
    );
};

export default BlogGrid;

