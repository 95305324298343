import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, TeamFour, CtaOne, AboutTwo, FooterTwo, HeaderOne, ServiceFour, ProcessTwo, TeamTwo, TestimonialThree, BlogFour, TeamHistory, TestimonialOne} from "../containers";

const CompanyMilestone = () => (
  <Fragment>
    <Seo title="COMPANY MILESTONE" />
    <HeaderTwo />
    <Breadcrumb pageName="Company Milestone" bgImage="images/miw/bar_001.jpg" />
    <TeamHistory className="" />
    {/*  <ServiceFour className="space-top space-extra-bottom"/> */}
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default CompanyMilestone;
