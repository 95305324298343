import React, { Fragment } from "react";
import { Seo, ScrollTopBtn, Breadcrumb } from "../components";
import { HeaderTwo, HeroOne, TeamIT, FooterTwo } from "../containers";

const ITSolution = () => (
  <Fragment>
    <Seo title="IT Solution" />
    <HeaderTwo />
    <Breadcrumb pageName="IT Solution" bgImage="images/miw/bar_015.jpg" />

    <TeamIT className="" />

    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default ITSolution;
