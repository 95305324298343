import Environment, { baseUrl } from "./Environment";

class BoardChatersPolicyService {
    async getBoardChatersPolicy(payload) {
        const response = await Environment.get(`${baseUrl}/api/board-charters-and-policy?${payload}`)
            .then((response) => {
                console.log(response);
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new BoardChatersPolicyService();