import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderTwo,
  TeamFour,
  CtaOne,
  AboutTwo,
  FooterTwo,
  HeaderOne,
  ServiceFour, 
  AboutSeven,
  TeamTwo,
  TestimonialThree,
  BlockQuote,
  TeamGateway,
  TestimonialOne
} from '../containers';

const GatewaySolution = ()=> (
  <Fragment>
    <Seo title="Gateway Solution" />
    <HeaderTwo />
    <Breadcrumb pageName="Gateway Solution" bgImage="images/miw/bar_002.jpg" />
   
      <TeamGateway className=""/>
   {/*  <ServiceFour className="space-top space-extra-bottom"/> */}


    <FooterTwo/>
    <ScrollTopBtn/>
  </Fragment>
);

export default GatewaySolution;