import React from 'react';

const InfoMediaContactingBoard = ({number, image, icon, title, info, className, ggmap }) => (
  <div className={`info-media ${className || ''}`}>
    <div className="media-icon">
      {image && <img src={image} alt="infoIcon"/>}
      {icon && <i className={icon}/>}      
      {number && number}      
    </div>
    <div className="media-body">
      {title && <span className="media-label">{title}</span>}
      {info && <div style={{whiteSpace: 'break-spaces'}} className="media-link">{info}</div>}
      {ggmap && <iframe src={ggmap} width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>}
    </div>
  </div>
);

export default InfoMediaContactingBoard;
