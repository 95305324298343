import React, { useState, useEffect, useRef } from "react";
import { Button } from "..";
import { baseUrl } from "../../services/Environment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { InfoMediaDownloadFinancial, TitleWrap } from "../../components";
import useGetBoardChartersPolicy from "../../hooks/useGetBoardChartersPolicy"
const qs = require('qs');

const FormContactingBoard = (props) => {
  const [errors, setErrors] = useState(false);
  const [description, setDescription] = useState('');
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);
  const [validFile, setValidFile] = useState(true);

  const [Urlfile, setUrlfile] = useState('/');

  const fileRef = useRef();
  const { policy,getBoardChatersPolicy,loading,setLoading} = useGetBoardChartersPolicy();
  // useEffect(() => {
  //     const queries = qs.stringify({
  //         populate: {
  //             BoardChaters: {
  //                 populate: ['File'],
  //             },
  //             Policy: {
  //                 populate: ['File'],
  //             }
  //         },
  //     }, {
  //         encodeValuesOnly: true, // prettify URL
  //     });
  //     getBoardChatersPolicy(queries);      
  // }, []);
  useEffect(() => {
      const queries = qs.stringify({
          populate: {
              BoardChaters: {
                  populate: ['File'],
              },
              Policy: {
                  populate: ['File'],
              }
          },
      }, {
          encodeValuesOnly: true,
      });

      getBoardChatersPolicy(queries);
      


  }, []); 


  
  const formHandle = async (event) => {
    event.preventDefault();
    const uploadFormData = new FormData();
    const formData = new FormData();

    const errors = validate({ ...inputs, ['description']: description, ['policy']: isAcceptPolicy });
    setErrors(errors);

    if (Object.keys(errors).length === 0 && validFile) {
      let responseFile = null;
      if (file) {
        uploadFormData.append('files', file);

        responseFile = await fetch(`${baseUrl}/api/upload`, {
          method: 'post',
          body: uploadFormData,
        }).then(res => {
          return res.json();
        }).catch(error => {
          throw new Error(error);
        });
      }

      const data = {
        Name: inputs.name,
        Email: inputs.email,
        Phone: inputs.phone,
        Description: description,
        Contact_type: props.contactType,
        File: responseFile == null ? null : responseFile[0].id,
      }

      formData.append('data', JSON.stringify(data));
      await fetch(`${baseUrl}/api/contact-forms`, {
        method: 'post',
        body: formData
      }).then(() => {
        setInputs({});
        setDescription('');
        setFile(null);
        fileRef.current.value = '';
        setIsAcceptPolicy(false);
        setValidFile(true);

        toast.success('Submit success', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch(error => {
        throw new Error(error);
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value)
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile && selectedFile.size <= 2 * 1024 * 1024) {
      setFile(selectedFile);
      setValidFile(true);
    } else {
      setValidFile(false);
    }
  }

  const handleAcceptPolicy = (event) => {
    setIsAcceptPolicy(event.target.checked);
  }

  // Validate
  const validate = (data) => {
    const errors = {};

    if (!data.email?.trim()) {
      errors.email = 'Email is required';
    }

    if (!data.phone?.trim()) {
      errors.phone = 'Phone is required';
    }

    if (!data.description?.trim()) {
      errors.description = 'Description is required';
    }

    if (!data.policy) {
      errors.policy = 'Policy is required';
    }

    return errors;
  }
  // console.log(policy.data)
  
 
  
  // setUrlfile('https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url);
  
  const handleClick = () => {
    // window.open('https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url);
    // window.location.href = 'https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url;
    setUrlfile('https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url);
    // console.log(Urlfile,(Urlfile != '/'))
    // eslint-disable-next-line eqeqeq
    if(Urlfile == '/'){
      setUrlfile('https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url);
      window.location.href = 'https://api.cheesediginet.com'+policy.data.attributes.Policy[2].File.data.attributes.url;
    }else{
      window.location.href = Urlfile;
    }

  };
  

  // useEffect(() => {
 
  // }, []);

  return (
    <form className="vs-contact-form">
      <div className="row gx-20">
        <div className="col-md-12 form-group">
          <input type="text" name="name" placeholder={props.langauge === 'th' ? 'ชื่อ-นามกสุล' : 'Name and surname'}
            onChange={handleChange}
            value={inputs.name || ""} />
          <i className="fal fa-user" />
        </div>
        <div className="col-md-12 form-group">
          <input type="email" name="email" placeholder={props.langauge === 'th' ? 'อีเมล์*' : 'Email*'}
            onChange={handleChange}
            value={inputs.email || ""} required />
          <i className="fal fa-envelope" />
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </div>
        <div className="col-md-12 form-group">
          <input type="text" name="phone" placeholder={props.langauge === 'th' ? 'เบอร์โทรศัพท์*' : 'Phone*'}
            onChange={handleChange}
            value={inputs.phone || ""} required />
          <i className="fal fa-phone" />
          {errors.phone && <span className="text-danger">{errors.phone}</span>}
        </div>
        <div className="col-12 form-group">
          <textarea name="description" placeholder={props.langauge === 'th' ? 'รายละเอียด*' : 'Description*'}
            onChange={handleChangeDescription}
            value={description} required />
          {errors.description && <span className="text-danger">{errors.description}</span>}
        </div>
        <div className="col-md-12 form-group">
          <input type="file" name="file" placeholder="" ref={fileRef}  onChange={handleFileChange}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
          <i className="fal  fa-file-alt" />
        </div>
        {!validFile ? <p className="text-danger">Please select a file that is no larger than 2MB.</p> : ''}
        <div className="col-12 form-group">
          <span>{props.langauge === 'th' ? 'สามารถแนบไฟล์ doc docx และ pdf (ขนาดไฟล์ไม่เกิน 2 MB)' : 'Please attach doc, docx or pdf files (Maximum file 2 MB).'}</span>
        </div>
        <div className="col-12 ">
          <div className="custom-checkbox notice">
            <input id="commentcheck" name="commentcheck" type="checkbox" value="yes" checked={isAcceptPolicy} onChange={handleAcceptPolicy} />

            {/* <label htmlFor="commentcheck">{props.langauge === 'th' ? 'ข้าพเจ้าได้อ่านและยอมรับเงื่อนไขตามระบุไว้ใน นโยบายคุ้มครองข้อมูลส่วนบุคคล ทุกประการ และให้ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูสส่วนบุคคลของข้าพเจ้า ตามวัตถุประสงค์ที่ได้มีการแจ้งไว้ข้างต้น' : 'I have read and accepted terms and conditions specified in the Personal Data Protection Policy and do hereby consent to the collecting, processing and/or disclosing of the personal data provided by me to fulfil the above-said purposes.'}</label> */}
            {/* <label htmlFor="commentcheck">{props.langauge === 'th' ? <p>ข้าพเจ้าได้อ่านและยอมรับเงื่อนไขตามระบุไว้ใน <a href={`https://api.cheesediginet.com${policy.data.attributes.Policy[2].File.data.attributes.url}`} style={{color: 'blue',textDecoration: 'underline'}} >นโยบายคุ้มครองข้อมูลส่วนบุคคล</a> ทุกประการ และให้ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูสส่วนบุคคลของข้าพเจ้า ตามวัตถุประสงค์ที่ได้มีการแจ้งไว้ข้างต้น</p> : <p>I have read and accepted terms and conditions specified in the <a href={'https://api.cheesediginet.com'} style={{color: 'blue',textDecoration: 'underline'}}>Personal Data Protection Policy</a> and do hereby consent to the collecting, processing and/or disclosing of the personal data provided by me to fulfil the above-said purposes.</p>}</label> */}
            <label htmlFor="commentcheck">
              {props.langauge === 'th' ? (
                <p>
                  ข้าพเจ้าได้อ่านและยอมรับเงื่อนไขตามระบุไว้ใน{' '}
                  <span
                    onClick={handleClick}
                    // href={`/`}
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    นโยบายคุ้มครองข้อมูลส่วนบุคคล
                  </span>{' '}
                  ทุกประการ และให้ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูสส่วนบุคคลของข้าพเจ้า
                  ตามวัตถุประสงค์ที่ได้มีการแจ้งไว้ข้างต้น
                </p>
              ) : (
                <p>
                  I have read and accepted terms and conditions specified in the{' '}
                  <span 
                    onClick={handleClick}
                    // href={'/'} 
                    style={{ color: 'blue', textDecoration: 'underline' }}>
                    Personal Data Protection Policy
                  </span>{' '}
                  and do hereby consent to the collecting, processing and/or disclosing of the personal data provided by me
                  to fulfil the above-said purposes.
                </p>
              )}
            </label>

            {errors.policy && <span className="text-danger">{errors.policy}</span>}
            {/* +policy.data.attributes.Policy[2].File.data.attributes.url */}

          </div>
        </div>
        <div className="col-12 pt-4">
          <Button onclick={formHandle}>
            {props.langauge === 'th' ? 'ส่งข้อมูล' : 'Submit'}
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
      <ToastContainer />
    </form>
  );
};

export default FormContactingBoard;
