import React, { useRef, useState, useEffect } from "react";
import { InfoMediaContactingBoard, SecTitle, TitleWrap, FormContactingBoard, FormFive } from "../../components";

const TeamContactingBoard = (props) => {
  const contactArea = useRef(null);
  const [lang, setLang] = useState();

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  return (
    <div className="container mb-100px">
      <TitleWrap>
        <div class="d-flex justify-content-center pt-60px">
          <h1 class="title-line">{lang === 'th' ? 'ช่องทางการติดต่อคณะกรรมการ' : 'Contact the Board'} </h1>
        </div>
        <h2 className="text-d-vision pt-4">
          {lang === 'th' ? 'บริษัท ชีส ดิจิตอล เน็ตเวิร์ค จำกัด (“บริษัท”) ได้จัดให้มีช่องทางการร้องเรียนและการแจ้งเบาะแส เพื่อรับเรื่องร้องเรียน หรือข้อคิดเห็น หรือข้อเสนอแนะจากผู้มีส่วนได้เสียที่ได้รับผลกระทบ หรือมีความเสี่ยงที่จะได้รับผลกระทบจากการดำเนินธุรกิจของบริษัท หรือจากการปฏิบัติหน้าที่ของกรรมการ ผู้บริหาร และพนักงาน ของบริษัท เกี่ยวกับการกระทำผิดกฎหมายหรือจรรยาบรรณ รวมถึงพฤติกรรมที่อาจส่อถึงการทุจริต หรือการละเมิดสิทธิ' : 'Cheese Digital Network Co., Ltd. ("Company") has provided channels for complaints and whistle blowing to receive complaints or comments or recommendations from affected stakeholders or may be at risk of being affected by the Company\'s business operations or from performing duties of directors, executives and employees of the Company regarding illegal acts or any infringement of the Company’s code of conduct, including behavior that may imply corruption or infringement.'}
        </h2>
      </TitleWrap>
      <div className="row">
        <div className="col-lg-6 mb-30">
          <div className="contact-box">
            <h3 className="contact-box__title h4 mb-4">
              <span>{lang === 'th' ? `ช่องทางอื่นๆ ในการร้องเรียน` : 'Channels for complaints'}</span>
              <br />
              <span>{lang === 'th' ? `และแจ้งเบาะแส` : 'and whistleblowing'}</span>
            </h3>
            <InfoMediaContactingBoard
              icon="far fa-users"
              title={lang === 'th' ? 'ติดต่อ' : 'Contact'}
              info={
                <>
                  <a>{lang === 'th' ? 'ประธานคณะกรรมการตรวจสอบ หรือ' : 'Chairman of the Audit Committee or'} </a>
                  <a>{lang === 'th' ? 'ประธานเจ้าหน้าที่บริหาร หรือ เลขานุการบริษัท' : 'Chief Executive Officer or Company Secretary'} </a>
                </>
              }
            />
            <InfoMediaContactingBoard
              icon="far fa-map-marker-alt"
              title={lang === 'th' ? 'ที่อยู่' : 'Address'}
              info={
                <>
                  <a>{lang === 'th' ? 'บริษัท ชีส ดิจิตอล เน็ตเวิร์ค จำกัด' : 'Cheese Digital Network Co., Ltd.'}</a>
                  <a>{lang === 'th' ? '2521/55-56 ถนนลาดพร้าว แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310' : '2521/55-56, Ladprao Road, Khongchaokhunsing Subdistrict, Wangthonglang District, Bangkok 10310'}</a>
                </>
              }
            />
            <InfoMediaContactingBoard
              icon="fal fa-envelope"
              title={lang === 'th' ? 'อีเมล์' : 'Email'}
              info={
                <>
                  <a>cgcoordinate@cheesediginet.com</a>
                  <a>{lang === 'th' ? '(เจ้าหน้าที่ประสานงานส่วนงานจรรยาบรรณและบรรษัทภิบาลเป็นผู้รับเรื่องเพื่อส่งต่อให้ผู้รับเรื่องร้องเรียนข้างต้น)' : '(Coordinator of Ethics and Corporate Governance)'} </a>
                </>
              }
            />
            <InfoMediaContactingBoard
              icon="fal fa-phone-alt"
              title={lang === 'th' ? 'โทรศัพท์' : 'Phone'}
              info={
                <>
                  <a href="tel:+310259121563">0-2530-0185</a>
                </>
              }
            />
            <InfoMediaContactingBoard icon="far fa-fax" title={lang === 'th' ? 'โทรสาร' : 'Fax'} info="0-2530-0172" />
          </div>
        </div>
        <div className="col-lg-6 mb-30">
          <div className="contact-box">
            <h3 className="contact-box__title h4">
              <span>{lang === 'th' ? 'แบบฟอร์มแจ้งเบาะแสการทุจริต' : 'Fraud and'}</span>
              <br />
              <span>{lang === 'th' ? 'และคอรัปชั่น' : 'Corruption Whistleblowing Form'}</span>
            </h3>
            {/* <p className="contact-box__text">We’re Ready To Help You</p> */}
            <FormContactingBoard contactType={props.contactType} langauge={lang} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamContactingBoard;
