import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamInvestorInfo } from "../containers";
import { useTranslation } from 'react-i18next';

const InvestorInfo = () => {
  const {t, i18n} = useTranslation();
  return (
    <Fragment>
      <Seo title={t('contact_us')} />
      <HeaderTwo />
      <Breadcrumb pageName="Investor Info" bgImage="images/miw/bar_012.jpg" />
      <TeamInvestorInfo className="" />
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default InvestorInfo;
