import React from "react";
import { useEffect, useRef, useState } from "react";
import "../../index.css";

function Modal() {
  const [isOpen, setIsOpen] = useState(true);
  const notice = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutSide = (e) => {
        if (notice.current && !notice.current.contains(e.target))
          setIsOpen(false);
      };
      document.addEventListener("mousedown", handleClickOutSide);
      return () =>
        document.removeEventListener("mousedown", handleClickOutSide);
    }
  }, [isOpen]);
  return (
    <div>
      {isOpen ? (
        <div>
          <div className="fixed flex justify-center items-center inset-0 bg-gray-400 opacity-50 z-20"></div>
          <div className="inset-0 fixed flex  justify-center items-center z-30">
            <div
              className="   bg-white 2xl:max-w-[40%] max-w-[100%]  opacity-100  2xl:p-3 p-2"
              ref={notice}
            >
              <div className="relative">
                <div
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className="absolute  sm:text-2xl  mobile:text-md z-40 right-0 cursor-pointer sm:p-3  mobile:p-1"
                >
                  &#10060;
                </div>
                <img src="/images/bg/แจ้งเตือน-01.jpg" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Modal;
