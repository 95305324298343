/** @format */

import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamPublisher} from "../containers";

const PublisherSolution = () => (
    <Fragment>
        <Seo title="Publisher Solution" />
        <HeaderTwo />
        <Breadcrumb pageName="Publisher Solution" bgImage="images/miw/bar-publisher-solution.jpg" />
        <TeamPublisher className="bg-black" />
        <FooterTwo />
        <ScrollTopBtn />
    </Fragment>
);

export default PublisherSolution;
