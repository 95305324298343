import React, { Fragment } from "react";
import { Seo, ScrollTopBtn, Breadcrumb } from "../components";
import { HeaderTwo, HeroOne, TeamOTPService, FooterTwo } from "../containers";

const OTPService = () => (
  <Fragment>
    <Seo title="OTP Service" />
    <HeaderTwo />
    <TeamOTPService />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default OTPService;
