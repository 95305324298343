import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamSignificantFinancial} from "../containers";

const SignificantFinancial = ()=> (
  <Fragment>
  <Seo title="Significant Financial" />
  <HeaderTwo />
  <Breadcrumb pageName="Significant Financial" bgImage="images/miw/bar_007.jpg" />
  <TeamSignificantFinancial className="" />
  <FooterTwo />
  <ScrollTopBtn />
</Fragment>
);

export default SignificantFinancial;