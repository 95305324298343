import React, { useRef, useState, useEffect } from "react";
import { SecTitle, TitleWrap, ImageBoxOne } from "../../components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TeamPublisher = ({ ...restProps }) => {
    gsap.registerPlugin(ScrollTrigger);
    const contactArea = useRef(null);
    const [lang, setLang] = useState();

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            behavior: "smooth",
            top: targetOffset,
        });
    };

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }

        gsap.from('.publisher-01', {
            scrollTrigger: '.publisher-section-01',
            x: 800,
            duration: 1,
            transformOrigin: "bottom center",
            ease: "none",
          });
    }, []);

    return (
        <div {...restProps}>
            <div className="container pb-100px">
                <TitleWrap>
                    <div class="d-flex justify-content-center pt-60px">
                        <h1 class="title-line text-white text-center">Publisher Solution</h1>
                    </div>
                    <h2 className="text-d-vision pt-4 text-white">
                        {(lang === 'th') ? 'โซลูชั่นในการให้บริการผลิตและลงโฆษณาออนไลน์ บนสื่อออนไลน์ของทางบริษัทฯ เองใช้ชื่อว่า “EDT Guide” ทำหน้าที่ในการรีวิว แนะนำ เชิญชวนให้ไปใช้บริการ สถานที่ท่องเที่ยว ร้านอาหาร โรงแรม ที่พัก และ คาเฟ่ โดยมีการให้บริการมายาวนานกว่า 12 ปีแล้ว และปัจจุบันได้รับการยอมรับในวงกว้าง'
                            : 'It is a solution for producing and posting online advertisements on our online media, namely "EDT Guide", to review, recommend and offer place of tourist attractions, restaurants, hotels, accommodations and cafes, which has been in service for more than 12 years and it is now widely accepted.'}
                    </h2>
                </TitleWrap>
                <div className="row gx-60 gy-2 justify-content-center">
                    <div className="col-lg-12">
                        <img className="publisher-01" src="images/miw/publisher-solution-1.png" alt="team-member" />
                    </div>
                </div>
                <div className="row gx-60 gy-2 justify-content-center">
                    <div className="col-lg-10 ">
                    <iframe width="640" height="360" src="https://www.youtube.com/embed/LFauO_w9G8I?autoplay=1" title="EDTGuide.com กิน ดื่ม เที่ยว เรื่องเดียวกัน" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                
                <div style={{position: 'absolute', bottom: '-10rem'}} className="publisher-section-01"></div>
            </div>
        </div>
    );
};

export default TeamPublisher;
