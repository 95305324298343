import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamShareholdingDirectors} from "../containers";

const ShareholdingDirectors = () => (
  <Fragment>
    <Seo title="Shareholding by Directors and Executives" />
    <HeaderTwo />
    <Breadcrumb pageName="Shareholding by Directors and Executives" bgImage="images/miw/bar.jpg" />
    <TeamShareholdingDirectors className="" />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);
export default ShareholdingDirectors;
