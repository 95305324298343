import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamContactingBoard } from "../containers";

const ContactingBoard = () => {
  return (
    <Fragment>
      <Seo title="Contact the Board" />
      <HeaderTwo />
      <Breadcrumb pageName="Contact the Board" bgImage="images/miw/bar_011.jpg" />
      <TeamContactingBoard contactType="board" className="" />
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ContactingBoard;
