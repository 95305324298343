import React, { useRef, useEffect, useState } from "react";
import { Button, FormThree, InfoMediaDownloadFinancial, ProgressOne, SecSubTitle, SecTitle, TitleWrap } from "../../components";
import { useTranslation } from "react-i18next";
import useShareholderMeeting from "../../hooks/useShareholderMeeting";
const qs = require('qs');

const TeamShareholderMeeting = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();
  const { years, shareholderMeeting, loading, getShareholderMeetingYears, getShareholderMeetingByYear } = useShareholderMeeting();
  const [currentYear, setCurrentYear] = useState('');
  const [lang, setLang] = useState();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }

    const queries = qs.stringify({
      sort: {
        Year: 'desc'
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getShareholderMeetingYears(queries);

    const queryByYear = qs.stringify({
      filters: {
        Year: {
          $eq: years?.data[0]?.attributes.Year
        }
      },
      sort: {
        Year: 'desc'
      },
      populate: {
        Documents: {
          populate: '*',
        },
        Reports: {
          populate: '*',
        }
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getShareholderMeetingByYear(queryByYear);
  }, []);

  const onChangeYear = (event) => {
    setCurrentYear(event.target.value);
    // event.preventDefault();
    const queries = qs.stringify({
      filters: {
        Year: {
          $eq: event.target.value
        }
      },
      populate: {
        Documents: {
          populate: '*',
        },
        Reports: {
          populate: '*',
        }
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getShareholderMeetingByYear(queries);
  }

  return (
    <div>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('shareholder_meeting_title')}</h1>
          </div>
        </TitleWrap>
        {years?.data.length === 0 ? <div className="text-center">{t('data_not_found')}</div> :
          <div className="row">
            <div className="col-6">
              <div class="d-flex flex-row align-items-center">
                <div className="p-2 text-26 text-w500">{t('shareholder_meeting_year')}</div>
                <div>
                  <select className="select-apn" style={{ width: "250px" }} onChange={onChangeYear} value={currentYear}>
                    {(!loading) ? years?.data.map((element, index) => {
                      return <option key={index} value={element?.attributes.Year}>{element?.attributes.Year}</option>
                    }) : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 pt-4">
              <hr className="hr-line"></hr>
            </div>
            <div className="col-12 text-center pt-4">
              <h3 className="contact-box__title h4 py-4">{t('shareholder_meeting_subtitle')} {currentYear ? currentYear : years?.data[0]?.attributes.Year}</h3>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="project-box">
                <h3 className="contact-box__title h4 pb-4">{t('shareholder_meeting_section_one')}</h3>
                {(!loading && shareholderMeeting?.data[0]?.attributes.Documents?.length > 0) ? shareholderMeeting?.data[0].attributes.Documents?.map((element) => {
                  return <InfoMediaDownloadFinancial file={element.File} key={element.id} icon="fas fa-file" info={lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'} />
                }) : ''}
              </div>
            </div>

            <div className="col-lg-6 mb-30">
              <div className="project-box">
                <h3 className="contact-box__title h4 pb-4">{t('shareholder_meeting_section_two')}</h3>
                {(!loading && shareholderMeeting?.data[0]?.attributes.Reports?.length > 0) ? shareholderMeeting?.data[0]?.attributes.Reports?.map((element) => {
                  return <InfoMediaDownloadFinancial file={element.File} key={element.id} icon="fas fa-file" info={lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'} />
                }) : ''}
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
};

export default TeamShareholderMeeting;
