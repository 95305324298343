/** @format */

import React, {useRef, useState, useEffect} from "react";
import {Button, FormThree, InfoMedia, ProgressOne, SecSubTitle, SecTitle, TitleWrap} from "../../components";

const TeamGateway = ({...restProps}) => {
  const contactArea = useRef(null);
  const [lang, setLang] = useState();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
  });

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
  }, []);

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap>
          {/* <SecSubTitle>วิสัยทัศน์</SecSubTitle> */}
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">Gateway Solution</h1>
          </div>
          <h2 className="text-d-vision pt-4">{lang === "th" ? "บริษัทฯได้ทำการพัฒนาแพล็ตฟอร์มที่ทำหน้าที่เป็นเสมือนประตูที่เชื่อมโยงไปสู่ระบบโทรศัพท์เคลื่อนที่หลักๆ ของประเทศไทยในปัจจุบัน เช่น AIS, Dtac และ True โดยจะแบ่งประเภทของ Gateway ออกเป็น 2 ลักษณะ" : "The Company has developed a platform acting as a gateway that connects to all major mobile operators in Thailand such as AIS, Dtac, and True. Gateways are divided into 2 types."}</h2>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-5 mb-30">
            <img src="images/miw/home-2.png" alt="team-member" />
          </div>
          <div className="col-lg-7 mb-4 d-flex flex-wrap align-content-center">
            <div className="">
              <div className="team-about mb-100px">
                <img className="gateway-title-h" src="images/gateway/gateway_01.png" alt="" />
                <p className="text-20">
                  {lang === "th"
                    ? "บริษัทฯได้พัฒนาโซลูชั่นเพื่อนำส่ง Digital Content จากผู้ผลิตคอนเท้นต์ (Content Creator) เช่น ข่าว, หุ้น, เนื้อหาบันเทิง เป็นต้น ไปสู่ผู้ใช้บริการโทรศัพท์เคลื่อนที่ (Subscribers) โดยมี พาร์ตเนอร์ทางการตลาด (Content Partner) เป็นผู้รับผิดชอบในการสื่อสารประชาสัมพันธ์"
                    : "The Company has developed solutions to deliver digital content from content creators such as news, stocks, entertainment content, etc. to mobile phone’s subscribers, and marketing content partner is responsible to communicate its digital content to mobile users."}
                </p>
              </div>
              <div className="team-about">
                <img className="gateway-title-h" src="images/gateway/gateway_02.png" alt="" />
                <p className="text-20 m-0">
                  {lang === "th"
                    ? "บริษัทฯได้พัฒนาโซลูชั่นร่วมกันกับผู้ให้บริการเครือข่าย ในการเรียกรับชำระเงิน ค่าสินค้าและบริการ ดิจิตอล (Wallpapers, Online Training, E-Voucher, Gaming Accessories, etc.) ผ่านระบบใบแจ้งหนี้ หรือ บิลค่าโทรศัพท์ทั้งในระบบเติมเงิน และ รายเดือน"
                    : "The Company has developed solutions in collaboration with network service operators for online electronic payment for digital products and services such as wallpapers, online training, E-Voucher, gaming accessories, etc. via invoicing system or mobile phone bills in both prepaid and postpaid systems."}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*   <div className="row mt-30">
                    <div className="col-md-6 mb-30">
                        <img src="images/miw/1264.jpg" alt="Blog" />
                    </div>
                    <div className="col-md-6 mb-30">
                        <img src="images/miw/1264.jpg" alt="Blog" />
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default TeamGateway;
