/** @format */

import React, { useRef, useState, useEffect } from "react";
import { SecTitle, TitleWrap } from "../../components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TeamAds = ({ ...restProps }) => {
    gsap.registerPlugin(ScrollTrigger);
    const contactArea = useRef(null);
    const [lang, setLang] = useState();

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            behavior: "smooth",
            top: targetOffset,
        });
    };

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }

        gsap.from('.advertising-01', {
            scrollTrigger: '.advertising-section-01',
            x: 500,
            duration: 1,
            transformOrigin: "bottom center",
            ease: "none",
          });
    }, []);

    return (
        <div {...restProps}>
            <div className="container">
                <TitleWrap>
                    <div class="d-flex justify-content-center pt-60px">
                        <h1 class="title-line text-center">Digital Advertising Solution</h1>
                    </div>
                    <h2 className="text-d-vision pt-4">
                        {(lang === 'th' ? 'บริการให้คำปรึกษา วางแผนและใช้งานโฆษณาออนไลน์ทุกประเภท โดยคลอบคลุม Digital Media ในทุกช่องทาง ทั้ง Social Media ต่างๆ เช่น Facebook, Google, Tiktok, Instagram และ LINE รวมทั้งยังคลอบคลุมไปจนถึงการทำการตลาดแบบ SMS Broadcasting และ E-Mail Marketing อีกด้วย ทั้งนี้บริษัทฯได้พัฒนาโซลูชั่นทางด้านการตลาดขึ้นมาเอง เพื่อนำมาให้บริการลูกค้าองค์กรและ SME อย่างครบวงจรและมีประสิทธิภาพโดยครอบคลุมตั้งแต่การผลิตชิ้นงานโฆษณาไปจนถึงการเลือกซื้อสื่อและการคิดคำนวณเรื่องความคุ้มค่าของการใช้งบโฆษณา โดยแบ่งย่อยออกเป็น'
                            : 'It is a consulting service for planning and executing all types of online advertising by covering Digital Media in all channels, including Social Media such as Facebook, Google, Tiktok, Instagram and LINE, as well as covering SMS Broadcasting and E-Mail Marketing. The Company has developed its own marketing solutions to serve corporate customers and SME comprehensively and efficiently. This covers everything from the production of the digital content to the selection of media and the Return on Investment (ROI). It is divided into;')}
                    </h2>
                </TitleWrap>
            </div>
            <div className="bg-color-black">
                <div className="container pb-100px">
                    <TitleWrap>
                        <div class="d-flex justify-content-center pt-60px">
                            <h1 class="title-line text-center"  style={{ color: 'var(--yellow-color)', lineHeight: '1' }}>Publisher Solution</h1>
                        </div>
                        <h2 className="text-d-vision pt-4 text-white">
                            {(lang === 'th') ? 'โซลูชั่นในการให้บริการผลิตและลงโฆษณาออนไลน์ บนสื่อออนไลน์ของทางบริษัทฯ เองใช้ชื่อว่า “EDT Guide” ทำหน้าที่ในการรีวิว แนะนำ เชิญชวนให้ไปใช้บริการ สถานที่ท่องเที่ยว ร้านอาหาร โรงแรม ที่พัก และ คาเฟ่ โดยมีการให้บริการมายาวนานกว่า 12 ปีแล้ว และปัจจุบันได้รับการยอมรับในวงกว้าง'
                                : 'It is a solution for producing and posting online advertisements on our online media, namely &quot;EDT Guide&quot;, to review, recommend and offer place of tourist attractions, restaurants, hotels, accommodations and cafes, which has been in service for more than 12 years and it is now widely accepted.'}
                        </h2>
                    </TitleWrap>
                    <div className="row gx-60 gy-2 justify-content-center">
                        <div className="col-lg-12">
                            <img className="publisher-01" src="images/miw/publisher-solution-1.png" alt="team-member" />
                        </div>
                    </div>
                    {/* <div className="row gx-60 gy-2 justify-content-center">
                        <div className="col-lg-10 ">
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/LFauO_w9G8I?autoplay=1" title="EDTGuide.com กิน ดื่ม เที่ยว เรื่องเดียวกัน" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div> */}
                    
                    <div style={{position: 'absolute', bottom: '-10rem'}} className="publisher-section-01"></div>
                </div>
            </div>
            <div className="container pb-100px">
            {/* <div className="container-fluid.px-0"> */}
                <TitleWrap>
                    <div class="d-flex justify-content-center pt-60px">
                        <h1 class="title-line text-center"  style={{ color: 'var(--yellow-color)', lineHeight: '1' }}>Social Media Solution</h1>
                    </div>
                    <h2 className="text-d-vision pt-4 ">
                        {(lang === 'th') ? 'ให้บริการวางแผนแคมเปญโฆษณา ซื้อสื่อโฆษณา และ รายงานผลสรุปของแคมเปญโฆษณา โดยนำเครื่องมือทางการตลาดที่พัฒนาขึ้นเพื่อตอบสนองวัตถุประสง ค์ที่แตกต่างกันของลูกค้าได้อย่างมีประสิทธิภาพในเวลาที่รวดเร็ว เช่น Social-Listening, Social-Media Analytics, Search Engine Optimization และ Customer Finder'
                            : 'It is a solution for planning marketing campaign, media buying and reporting on the results of advertising campaigns. The Company has implemented our developed marketing tools to meet different objectives of customers efficiently and quickly, such as Social-Listening, Social-Media Analytics, Search Engine Optimization and Customer Finder.'}
                    </h2>
                </TitleWrap>
                {/* <div className="advertising-container"> */}
                <div className="advertising-container-01">
                    {/* <div> */}
                    <div style={{ overflow: 'hidden' }}>
                    {/* <div className="advertising-inner-container"> */}
                        {/* <div> */}
                            {/* <img className="advertising-img-position" src="images/miw/advertising-02.png" alt="Cheese-Diginet" /> */}
                            {/* <img className="advertising-img-line-position" src="images/miw/advertising-03.png" alt="Cheese-Diginet" /> */}
                        {/* </div> */}
                        
                        <dir className="advertising-section-01">
                            <h1 className="m-0" style={{ color: 'var(--yellow-color)', lineHeight: '1' }}>Planning</h1>
                            <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> Communication Strategy & Media Plan</p>
                            <h1 className="m-0" style={{ color: 'var(--theme-color)', lineHeight: '1' }}>Media Buying</h1>
                            <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> Social Media (Facebook, Instagram, Twitter, LinkedIn, TIKTOK)</p>
                            {/* <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> Instagram, Twitter, LinkedIn, TIKTOK)</p> */}
                            <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> Influencer</p>
                            <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> Google Ads</p>
                            <p className="m-0 text-dark"><i className="fa fa-caret-right"></i> LINE Ads</p>
                        </dir>
                        <img className="advertising-img-position" src="images/miw/advertising-04.png" alt="Cheese-Diginet" />
                    </div>
                    {/* <div> */}
                    <div style={{ overflow: 'hidden', 'margin-left': '-15px' }}>
                    {/* <div style={{ overflow: 'hidden' }}> */}
                        <img 
                            className="advertising-01"
                            style={{ maxWidth: 'unset', position: 'relative', right: '-1rem', height: '400px'}} 
                            src="images/miw/advertising-01.png" alt="Cheese-Diginet" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamAds;
