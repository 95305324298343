import React from 'react';
import { Link } from 'react-router-dom';
import ModalImage from "react-modal-image";


const Breadcrumb = ({ pageName, bgImage }) => {

    function BreadcrumbTitle(props) {
        // let pageNames = props.pageName.split(' ');
        return (
            <>
            {/* <ModalImage
                small={`/images/sms/SMS-09.png`}
                large={`/images/sms/SMS-15.jpg`}
                alt="ค่าบริการ"
            /> */}

            <img src="/images/sms/SMS-09.png" alt="SMS MARKETING" />
            <div className='row'>
                
                <div className='col-lg-6' style={{ 'text-align' : 'center' ,'padding-bottom': '10px'}}><Link to={`/smssolution-details/sms-marketing`}><img className='img_sms' src="/images/sms/SMS-10_0.png" alt="ติดต่อสั่งซื้อ" /></Link></div>
               
                <div className='col-lg-6'  style={{ 'text-align' : 'center' ,'padding-bottom': '10px'}}><Link to={`/smssolution-details/contact-us`}><img className='img_sms' src="/images/sms/SMS-11_0.png" alt="ทดลองบริการส่ง SMS ฟรี" /></Link></div>
            </div>
            <img src="/images/sms/SMS-12_0.png" alt="ส่ง SMS Marketing, SMS OTP, SMS API ครบจบที่เดียว" />

            </>
        )
        // if (pageNames.length > 1) {
        //     return pageNames.map((pageName, index) => {
        //         if (index === 0) {
        //             return <h1 className='m-0' style={{   'text-transform': 'uppercase', fontSize: '60px', color: 'var(--yellow-color)', lineHeight: '1' }}>{pageName}</h1>
        //         } else {
        //             return <span className='m-0' style={{ 'text-transform': 'uppercase', fontSize: '40px', color: '#fff', lineHeight: '1' }}> {pageName} </span>
        //         }
                
        //     });
        // } else if (pageNames.length === 1) {
        //     return <h1 className='m-0' style={{ fontSize: '60px', color: 'var(--yellow-color)', lineHeight: '1' }}>{pageName}</h1>
        // }
    }

    return (
        <>
            <div className="breadcumb-wrapper-modify background-image" style={{ backgroundImage: `url('/${bgImage}')`,padding :0 }}>
                <div className="container z-index-common sms-detail">
                    <div className="breadcumb-content" style={{ display: 'block' }}>
                        {/* <h1 className="breadcumb-title">{handleBreadcrumbTitle(pageName)}</h1> */}
                       
                        <BreadcrumbTitle pageName={pageName} />
                        <div className="breadcumb-menu-wrap d-hidden">
                            <ul className="breadcumb-menu">
                                {/* <li className="h1"><Link to="/">Home</Link></li> */}
                                {/* <li className="h1">{pageName}</li> */}
                            </ul>
                        </div>
      
                    </div>
                </div>
            </div>
        </>
    )
};

export default Breadcrumb;