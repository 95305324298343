import Environment, { baseUrl } from "./Environment";

class ShareholderMeetingService {
    async getShareholderMeetingYears(params) {
        const response = await Environment.get(`${baseUrl}/api/shareholder-meetings?${params}&populate=*`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getShareholderMeetingByYear(payload) {
        const response = await Environment.get(`${baseUrl}/api/shareholder-meetings?${payload}`)
            .then((response) => {
                return response.data;
            }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new ShareholderMeetingService();