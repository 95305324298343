import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamContactInvestor } from "../containers";
import { useTranslation } from 'react-i18next';

const ContactInvestor = () => {
  const { t, i18n } = useTranslation();
  return (
    <Fragment>
      <Seo title={t('contact_investor')} />
      <HeaderTwo />
      <Breadcrumb pageName='Contact Investor relations' bgImage="images/miw/bar_009.jpg" />
      <TeamContactInvestor contactType="investor" className="" />
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ContactInvestor;
