import React, {Fragment} from "react";
import {Seo, ScrollTopBtn, Breadcrumb} from "../components";
import {HeaderTwo, AboutFinancialStatements, TeamFinancialStatements, TeamSustainabilityReport, FooterTwo} from "../containers";

const SustainabilityReport = () => (
  <Fragment>
    <Seo title="Sustainability Report" />
    <HeaderTwo />
    <Breadcrumb pageName="Sustainability Report" bgImage="images/miw/bar.jpg" />
    <TeamSustainabilityReport/>
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default SustainabilityReport;
