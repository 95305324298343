import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {
  Button,
  InfoMedia,
  FormFive,
  TitleWrap,
  SecTitle,
} from "../../components";
import { useTranslation } from "react-i18next";

const TeamSustainabilityReport = ({ ...restProps }) => {
  const years = [];
  const { t, i18n } = useTranslation();

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">รายงานการพัฒนาอย่างยั่งยืน</h1>
          </div>
        </TitleWrap>
        {years?.length === 0 ? (
          <div className="text-center">{t("data_not_found")}</div>
        ) : (
          <>
            <div className="row bg-room mb-75px">
              <div className="col-lg-4 offset-lg-2 mb-30">
                <img src="images/miw/book-1.jpg" alt="team-member" />
              </div>
              <div className="col-lg-4 mb-30 d-flex align-items-center">
                <div className="d-flex flex-column">
                  <div className="p-2">
                    <div className="text-report">
                      <p>รายงานความยั่งยืน</p>
                      <span>2565</span>
                    </div>
                  </div>
                  <div className="p-2">
                    {" "}
                    <Button className="style5">
                      ดูออนไลน์
                      <i className="fas fa-book-open" />
                    </Button>{" "}
                    <Button className="style5">
                      ดาวน์โหลด
                      <i className="fas fa-download" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-30">
                <div class="d-flex flex-row align-items-center">
                  <div className="p-2 text-26 text-w500">ประจำปี</div>
                  <div>
                    <select className="select-apn" style={{ width: "250px" }}>
                      <option defaultValue="">2565</option>
                      <option defaultValue="">2566</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <table className="table-finalux">
                  <thead className="thead-finalux">
                    <tr>
                      <th
                        style={{ width: "70%" }}
                        className="text-center text-white"
                      >
                        2565
                      </th>
                      <th
                        style={{ width: "15%" }}
                        className="text-center text-white"
                      >
                        ดูออนไลน์
                      </th>
                      <th
                        style={{ width: "15%" }}
                        className="text-center text-white"
                      >
                        ดาวน์โหลด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>งบการเงิน ประจำปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img
                          className="icon-dl"
                          src="images/miw/download.png"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 3 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img
                          className="icon-dl"
                          src="images/miw/download.png"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 2 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img
                          className="icon-dl"
                          src="images/miw/download.png"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 1 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img
                          className="icon-dl"
                          src="images/miw/download.png"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {/*  <Tab.Container id="contactTab" defaultActiveKey="tabno1">
        <Nav className="contact-tab-menu">
          <Nav.Link eventKey="tabno1">
            <span className="btn-img">
              <img src="images/miw/report-01.png" alt="tabicon" />
            </span>
            <span className="btn-title h6">รายงานประจำปี</span>
          </Nav.Link>
          <Nav.Link eventKey="tabno2">
            <span className="btn-img">
              <img src="images/miw/report-02.png" alt="tabicon" />
            </span>
            <span className="btn-title h6">แบบฟอร์ม 56-1</span>
          </Nav.Link>
          <Nav.Link eventKey="tabno3">
            <span className="btn-img">
              <img src="images/miw/report-03.png" alt="tabicon" />
            </span>
            <span className="btn-title h6">รายงานความยั่งยืน</span>
          </Nav.Link>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="tabno1">
            <div className="row bg-room mb-75px">
              <div className="col-lg-4 offset-lg-2 mb-30">
                <img src="images/miw/book-1.jpg" alt="team-member" />
              </div>
              <div className="col-lg-4 mb-30 d-flex align-items-center">
                <div className="d-flex flex-column">
                  <div className="p-2">
                    <div className="text-report">
                      <p>รายงานความยั่งยืน</p>
                      <span>2565</span>
                    </div>
                  </div>
                  <div className="p-2">
                    {" "}
                    <Button className="style5">
                      ดูออนไลน์
                      <i className="fas fa-book-open" />
                    </Button>{" "}
                    <Button className="style5">
                      ดาวน์โหลด
                      <i className="fas fa-download" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <table className="table-finalux">
                  <thead className="thead-finalux">
                    <tr>
                      <th style={{width: "70%"}} className="text-center text-white">
                        2565
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดูออนไลน์
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดาวน์โหลด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>งบการเงิน ประจำปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 3 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 2 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 1 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tabno2">
          <div className="row bg-room mb-75px">
              <div className="col-lg-4 offset-lg-2 mb-30">
                <img src="images/miw/book-2.jpg" alt="team-member" />
              </div>
              <div className="col-lg-4 mb-30 d-flex align-items-center">
                <div className="d-flex flex-column">
                  <div className="p-2">
                    <div className="text-report">
                      <p>รายงานความยั่งยืน</p>
                      <span>2565</span>
                    </div>
                  </div>
                  <div className="p-2">
                    {" "}
                    <Button className="style5">
                      ดูออนไลน์
                      <i className="fas fa-book-open" />
                    </Button>{" "}
                    <Button className="style5">
                      ดาวน์โหลด
                      <i className="fas fa-download" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <table className="table-finalux">
                  <thead className="thead-finalux">
                    <tr>
                      <th style={{width: "70%"}} className="text-center text-white">
                        2565
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดูออนไลน์
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดาวน์โหลด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>งบการเงิน ประจำปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 3 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 2 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 1 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tabno3">
          <div className="row bg-room mb-75px">
              <div className="col-lg-4 offset-lg-2 mb-30">
                <img src="images/miw/book-3.jpg" alt="team-member" />
              </div>
              <div className="col-lg-4 mb-30 d-flex align-items-center">
                <div className="d-flex flex-column">
                  <div className="p-2">
                    <div className="text-report">
                      <p>รายงานความยั่งยืน</p>
                      <span>2565</span>
                    </div>
                  </div>
                  <div className="p-2">
                    {" "}
                    <Button className="style5">
                      ดูออนไลน์
                      <i className="fas fa-book-open" />
                    </Button>{" "}
                    <Button className="style5">
                      ดาวน์โหลด
                      <i className="fas fa-download" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <table className="table-finalux">
                  <thead className="thead-finalux">
                    <tr>
                      <th style={{width: "70%"}} className="text-center text-white">
                        2565
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดูออนไลน์
                      </th>
                      <th style={{width: "15%"}} className="text-center text-white">
                        ดาวน์โหลด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>งบการเงิน ประจำปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 3 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 2 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                    <tr>
                      <td>งบการเงิน ไตรมาสที่ 1 ปี 2565</td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/online.png" />
                      </td>
                      <td className="text-center">
                        <img className="icon-dl" src="images/miw/download.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container> */}
      </div>
    </div>
  );
};

export default TeamSustainabilityReport;
