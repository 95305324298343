import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, TeamFour, CtaOne, AboutTwo, FooterTwo, HeaderOne, ServiceFour, AboutSeven, TeamTwo, TestimonialThree, BlogFour, TeamVision, TestimonialTwo} from "../containers";

const Vision = () => (
  <Fragment>
    <Seo title="Vision" />
    <HeaderTwo />
    <AboutSeven />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default Vision;
