import { useState, useEffect } from "react";
import FinancialStatementService from "../services/FinancialStatementService";

export default function useFinancialStatement() {
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState(null);
    const [financialStatement, setFinancialStatement] = useState(null);
    const [lang, setLang] = useState();

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return {
        loading,
        years,
        financialStatement,
        setYears: (payload) => {
            setYears(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getFinancialStatementYears: async (payload) => {
            setLoading(true);
            let responseData;
            if (payload) {
                responseData = await FinancialStatementService.getFinancialStatementYears(payload);
            } else {
                const queries = {
                    _limit: 12,
                };
                responseData = await FinancialStatementService.getShareholderMeetingYears(queries);
            }
            if (responseData) {
                setYears(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        },

        getFinancialStatementByYear: async (payload) => {
            setLoading(true);
            const responseData = await FinancialStatementService.getFinancialStatementByYear(payload);
            if (responseData) {
                setFinancialStatement(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}