import React, { Fragment, useEffect } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamPolicy } from "../containers";
import useGetBoardChartersPolicy from "../hooks/useGetBoardChartersPolicy";
const qs = require('qs');

const Policy = () => {
    const { loading, policy, getBoardChatersPolicy } = useGetBoardChartersPolicy();

    useEffect(() => {
        const queries = qs.stringify({
            populate: {
                BoardChaters: {
                    populate: ['File'],
                },
                Policy: {
                    populate: ['File'],
                }
            },
        }, {
            encodeValuesOnly: true, // prettify URL
        });
        getBoardChatersPolicy(queries);
    }, []);
    return (
        <Fragment>
            <Seo title="Board Charters and Corporate Governance Policy" />
            <HeaderTwo />
            <Breadcrumb pageName="Board Charters and Corporate Governance Policy" bgImage="images/miw/bar_008.jpg" />
            {(!loading) ?
                <TeamPolicy data={policy?.data.attributes} /> : null
            }
            <FooterTwo />
            <ScrollTopBtn />
        </Fragment>
    );

};

export default Policy;