/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { Logo, IconLink, Widget, AboutWidget, CategoryWidget, FormTwo, TableOne } from "../../components";
import { useTranslation } from 'react-i18next';

// Widget Datas
import widgetsdata from "../../data/widgets.json";

const { pageList, exploreLinks } = widgetsdata;

const FooterTwo = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer className="footer-wrapper footer-layout2 bg-footer-sticky">
            {/* Row 1 */}
            <div className="footer-top">
                <div className="container">
                    <div className="row align-items-center justify-content-between gy-30 py-4">
                        <div className="col-sm-auto">{/* <Logo image="images/logo-white.png"/> */}</div>
                        <div className="col-sm-auto">
                            <IconLink className="footer-social" title="Follow Us On:">
                                <IconLink.Item icon="fab fa-facebook-f" path="/" />
                                <IconLink.Item icon="fab fa-twitter" path="/" />
                                {/* <IconLink.Item icon="fab fa-behance" path="/" /> */}
                                <IconLink.Item icon="fab fa-youtube" path="/" />
                                <IconLink.Item icon="fab fa-instagram" path="/" />
                            </IconLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* ./ Row 1 */}

            {/* Row 2 */}
            <div className="widget-area">
                <div className="container">
                    <div className="row justify-content-between gx-80">
                        <div className="col-md-6 col-lg-4 col-xl-4 col-xxl order-2 order-lg-1">
                            <Widget widgetTitle="" className="footer-widget">
                                <AboutWidget>
                                    <Logo image="/images/logo-white.png" />
                                    {/* <p className="text-footer-apn p-0 m-0">{t('company_name')}</p> */}
                                    <p className="text-footer-apn p-0 m-0">{t('company_service')}</p>
                                    <p className="text-footer-apn py-4 m-0">{t('taxpayer_id')} 0105547092109</p>
                                </AboutWidget>
                            </Widget>
                        </div>
                        <div className="col-lg-4 col-xl-4 order-1 order-lg-2 d-flex flex-column justify-content-center align-items-start">
                            {/* <h4 className="title h6">Facebook:</h4>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fm.facebook.com%2Fcheesedigitalnetwork%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="200" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>{" "} */}
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-4 order-3">
                            <Widget widgetTitle="" className="footer-widget d-flex flex-column justify-content-center">
                                <img className="mt-2" style={{width:"55%"}} src="images/miw/Contact Us-02.png" alt="" />
                                <p style={{ whiteSpace: "break-spaces"}} className="text-bold text-footer-apn p-0 m-0 pt-4" >{t('company_address')} </p>
                                <p className="text-footer-apn p-0 m-0"> {t('phone')} : (66) 2 530 0185-6 </p>
                                <p className="text-footer-apn p-0 m-0"> {t('fax')} : (66) 2 530 0172</p>
                                <p className="text-footer-apn p-0 m-0">E-mail : admin@cheesediginet.com</p>
                            </Widget>
                        </div>
                    </div>
                </div>
            </div>
            {/* ./ Row 2 */}

            {/* Row 3 */}
            <div className="copyright-wrap">
                <div className="container">
                    <p className="copyright-text">
                        Copyright <i className="fal fa-copyright" /> 2023{" "}
                        <Link className="text-white" to="/">
                            Cheese Digital Network Co., Ltd.
                        </Link>{" "}
                        {/* All rights reserved by <a className="text-white" href="https://themeforest.net/user/vecuro_themes">Vecuro</a>. */}
                    </p>
                </div>
            </div>
            {/* ./ Row 3 */}
        </footer>
    );
};

export default FooterTwo;
