import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamShareholderStructure} from "../containers";

const ShareholderStructure = () => (
    <Fragment>
        <Seo title="Shareholders Structure" />
        <HeaderTwo />
        <Breadcrumb pageName="Shareholders Structure" bgImage="images/miw/bar.jpg" />
        <TeamShareholderStructure className="" />
        <FooterTwo />
        <ScrollTopBtn />
    </Fragment>
);

export default ShareholderStructure;
