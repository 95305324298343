import React, { useEffect, useRef, useState } from "react";
import { InfoMediaContactingBoard, TitleWrap, FormContactingBoard, FormFive } from "../../components";
import { useTranslation } from 'react-i18next';

const TeamContactInvestor = (props) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  return (
    <div >
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('contact_investor')}</h1>
          </div>
        </TitleWrap>
        <div className="row">
          <div className="col-lg-6 mb-30">
            <div className="contact-box">
              <InfoMediaContactingBoard
                icon="fal fa-phone-alt"
                title={t('phone')}
                info={
                  <>
                    <a href="tel:+310259121563">0-2530-0185</a>
                  </>
                }
              />
              <InfoMediaContactingBoard icon="far fa-fax" title={t('fax')} info="0-2530-0172" />
              <InfoMediaContactingBoard icon="fas fa-envelope" title="E-mail" info="ir@cheesediginet.com" />
            </div>
          </div>
          <div className="col-lg-6 mb-30">
            <div className="contact-box">
            <FormContactingBoard contactType={props.contactType} langauge={lang} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamContactInvestor;
