import React from "react";

function Table() {
  return (
    <table className="containe scroll mx-auto text-black mobile:w-[500px] sm:w-full border-none border-separate lg:border-spacing-2 border-spacing-1   text-center 2xl:text-[30px]   lg:text-[20px] md:text-[15px] sm:text-[13px] mobile:text-[7px]   mb-2">
      <tr>
        <th className="HTyellow">Package</th>
        <th className="HTgray ">
          Period <br /> <span>(Month)</span>
        </th>
        <th className="HTyellow">
          SMS <br /> Amount
        </th>
        <th className="HTgray">
          Package <br />
          Price
        </th>
        <th className="HTyellow">
          Selling <br /> Price/SMS
        </th>
        <th className=" bg-bg_gray lg:rounded-b sm:rounded-b rounded-b sm:rounded-3xl rounded-xl font-semibold leading-[1] lg:pt-7 sm:pt-4 pt-2 sm:px-12 px-4  ">
          รายละเอียดแพ็คเกจ
        </th>
      </tr>
      <tr>
        <td className="TLyellow ">1</td>
        <td className="TLgray">6</td>

        <td className="TLyellow">3,000</td>
        <td className="TLgray ">1,800</td>
        <td className="TLyellow">0.60</td>

        <td className="TLgrayLong">
          จ่ายเพียง 1,800 บาท ใช้งานได้สูงสุด 3,000 เครดิต <br /> ระยะเวลา 6
          เดือน
        </td>
      </tr>

      <tr className="z-40 shadow-[0px_3px_20px_5px_rgba(235,188,20,0.82)]    ">
        <td className="Tyellow ">2</td>
        <td className="Tgray">6</td>
        <td className="Tyellow">10,000</td>
        <td className="Tgray">5,500</td>
        <td className="Tyellow">0.55</td>
        <td className="TLgrayLong ">
          จ่ายเพียง 5,500 บาท ใช้งานได้สูงสุด 10,000 เครดิต <br /> ระยะเวลา 6
          เดือน
        </td>
      </tr>
      <tr className="z-30 ">
        <td className="TLyellow ">3</td>
        <td className="TLgray ">12</td>
        <td className="TLyellow">50,000</td>
        <td className="TLgray">20,000</td>
        <td className="TLyellow">0.40</td>
        <td className="TLgrayLong">
          จ่ายเพียง 20,000 บาท ใช้งานได้สูงสุด 50,000 เครดิต <br /> ระยะเวลา 12
          เดือน
        </td>
      </tr>
      <tr>
        <td className="TLyellow">4</td>
        <td className="TLgray">12</td>
        <td className="TLyellow">100,000</td>
        <td className="TLgray">35,000</td>
        <td className="TLyellow">0.35</td>

        <td className="TLgrayLong">
          จ่ายเพียง 35,000 บาท ใช้งานได้สูงสุด 100,000 เครดิต <br /> ระยะเวลา 12
          เดือน
        </td>
      </tr>
      <tr>
        <td className="TLyellow">5</td>
        <td className="TLgray">12</td>
        <td className="TLyellow">300,000</td>
        <td className="TLgray">90,000</td>
        <td className="TLyellow">0.30</td>
        <td className="TLgrayLong">
          จ่ายเพียง 90,000 บาท ใช้งานได้สูงสุด 300,000 เครดิต <br /> ระยะเวลา 12
          เดือน
        </td>
      </tr>
      <tr>
        <td className="TLyellow">6</td>
        <td className="TLgray">12</td>
        <td className="TLyellow">500,000</td>
        <td className="TLgray">140,000</td>
        <td className="TLyellow">0.28</td>
        <td className="TLgrayLong">
          จ่ายเพียง 140,000 บาท ใช้งานได้สูงสุด 500,000 เครดิต <br /> ระยะเวลา
          12 เดือน
        </td>
      </tr>
    </table>
  );
}

export default Table;
