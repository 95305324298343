import Environment, { baseUrl } from "./Environment";

class ExecutiveService {
    async getExecutives(params) {
        const response = await Environment.get(`${baseUrl}/api/executives?${params}&populate=*`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getExecutiveById(payload) {
        const response = await Environment.get(`${baseUrl}/api/executives/${payload}}?populate=*`)
            .then((response) => {
                return response.data;
            }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new ExecutiveService();