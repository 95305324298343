import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamOurClients} from "../containers";

const OurClients = ()=> (
  <Fragment>
  <Seo title="Publisher Solution" />
  <HeaderTwo />
  <Breadcrumb pageName="Our Clients" bgImage="images/miw/bar_004.jpg" />
  <div className="bg-our-clients">
    <TeamOurClients className="" />
  </div>
  <FooterTwo />
  <ScrollTopBtn />
</Fragment>
);

export default OurClients;