import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ pageName, bgImage }) => {

    function BreadcrumbTitle(props) {
        let pageNames = props.pageName.split(' ');

        if (pageNames.length > 1) {
            return pageNames.map((pageName, index) => {
                if (index === 0) {
                    return <h1 className='m-0' style={{   'text-transform': 'uppercase', fontSize: '60px', color: 'var(--yellow-color)', lineHeight: '1' }}>{pageName}</h1>
                } else {
                    return <span className='m-0' style={{ 'text-transform': 'uppercase', fontSize: '40px', color: '#fff', lineHeight: '1' }}> {pageName} </span>
                }
                
            });
        } else if (pageNames.length === 1) {
            return <h1 className='m-0' style={{ fontSize: '60px', color: 'var(--yellow-color)', lineHeight: '1' }}>{pageName}</h1>
        }
    }

    return (
        <>
            <div className="breadcumb-wrapper background-image" style={{ backgroundImage: `url('${bgImage}')` }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content" style={{ display: 'block' }}>
                        {/* <h1 className="breadcumb-title">{handleBreadcrumbTitle(pageName)}</h1> */}
                        <BreadcrumbTitle pageName={pageName} />
                        <div className="breadcumb-menu-wrap d-hidden">
                            <ul className="breadcumb-menu">
                                <li className="h1"><Link to="/">Home</Link></li>
                                <li className="h1">{pageName}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Breadcrumb;