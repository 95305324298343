/** @format */

import React, { useRef, useState, useEffect } from "react";
import { Button, FormThree, InfoMedia, ProgressOne, SecSubTitle, SecTitle, TitleWrap } from "../../components";
import historyData from "../../data/history.json";

const TeamHistory = ({ ...restProps }) => {
    const [title, setTitle] = useState('ประวัติความเป็นมา');
    const [milestone, setMilestone] = useState('เหตุการณ์ที่สำคัญ');
    const [lang, setLang] = useState();
    const contactArea = useRef(null);

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            behavior: "smooth",
            top: targetOffset,
        });
    };

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            const lang = localStorage.getItem('cheese_lang');
            if (lang === 'en') {
                setTitle('Company Milestone');
                setMilestone('Milestone');
                setLang('en');
            } else {
                setTitle('ประวัติความเป็นมา');
                setMilestone('เหตุการณ์สำคัญ');
                setLang('th');
            }
        } else {
            setTitle('ประวัติความเป็นมา');
            setMilestone('เหตุการณ์สำคัญ');
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            const lang = localStorage.getItem('cheese_lang');
            if (lang === 'en') {
                setTitle('Company Milestone');
                setMilestone('Milestone');
                setLang('en');
            } else {
                setTitle('ประวัติความเป็นมา');
                setMilestone('เหตุการณ์สำคัญ');
                setLang('th');
            }
        } else {
            setTitle('ประวัติความเป็นมา');
            setMilestone('เหตุการณ์สำคัญ');
            setLang('th');
        }
    }, []);

    return (
        <div {...restProps}>
            <div>
            <img className="w-100" src={lang === 'th' ? 'images/miw/company_milestone_th.jpg' : 'images/miw/company_milestone_eng.jpg'} alt="Cheese-Diginet" />
            </div>
            <div className="container mb-100px d-hidden">
                <TitleWrap className="text-center">
                    <div class="d-flex justify-content-center pt-60px">
                        <h1 class="title-line">{title}</h1>
                    </div>
                </TitleWrap>
                <div className="timeline">
                    {historyData.map((element, index) => {
                        if (index % 2 === 0) {
                            return <>
                                <div className="timeline__component">
                                    <div className="timeline__date timeline__date--right">{element.period}</div>
                                </div><div className="timeline__middle">
                                    <div className="timeline__point"></div>
                                </div><div className="timeline__component timeline__component--bg">
                                    <h2 className="timeline__title">{milestone}</h2>
                                    {(lang === 'th') ? element.milestoneTh.map((elm, index) => {
                                        return <p className="timeline__paragraph pt-2">
                                            <i className="fa fa-clone" />
                                            &nbsp;{elm}
                                        </p>
                                    }) : element.milestoneEn.map((elm, index) => {
                                        return <p className="timeline__paragraph pt-2">
                                            <i className="fa fa-clone" />
                                            &nbsp;{elm}
                                        </p>
                                    })};
                                </div>
                            </>
                        } else {
                            return <>
                                <div className="timeline__component timeline__component--bg">
                                    <h2 className="timeline__title">{milestone}</h2>
                                    {(lang === 'th') ? element.milestoneTh.map((elm, index) => {
                                        return <p className="timeline__paragraph pt-2">
                                            <i className="fa fa-clone" />
                                            &nbsp;{elm}
                                        </p>
                                    }) : element.milestoneEn.map((elm, index) => {
                                        return <p className="timeline__paragraph pt-2">
                                            <i className="fa fa-clone" />
                                            &nbsp;{elm}
                                        </p>
                                    })};
                                </div>
                                <div className="timeline__middle">
                                    <div className="timeline__point"></div>
                                    {/* <div className="timeline__point timeline__point--bottom"></div> */}
                                </div>
                                <div className="timeline__component">
                                    <div className="timeline__date">{element.period}</div>
                                </div>
                            </>
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default TeamHistory;
