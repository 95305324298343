import React, { useRef } from "react";
import { TitleWrap } from "../../components";
import { useTranslation } from 'react-i18next';

const TeamPayment = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('dividend_policy_payment_title')}</h1>
          </div>
        </TitleWrap>
        <div className="row">
          <div className="col-12">
            <p className="team-name board-name">{t('dividend_policy')}</p>
          </div>
          <div className="col-12 mb-2">
            <p style={{ whiteSpace: 'break-spaces' }} className="text-d-vision">{t('dividend_policy_description')}</p>
          </div>
          <div className="col-12 mb-2">
            <p style={{ whiteSpace: 'break-spaces' }} className="text-d-vision">{t('dividend_policy_description')}</p>
          </div>
          <div className="col-12 mb-2">
            <p style={{ whiteSpace: 'break-spaces' }} className="text-d-vision">{t('dividend_policy_description')}</p>
          </div>
          <div className="col-12 mb-2">
            <p className="team-name board-name">{t('history_dividend')}</p>
          </div>
          <div className="col-12">
            <table className="table-greenux">
              <thead className="thead-greenux">
                <tr>
                  <th style={{ width: "22%" }} className="text-center text-white">{t('approval_date')}</th>
                  <th style={{ width: "15%" }} className="text-center text-white">{t('payment_date')}</th>
                  <th className="text-center text-white">{t('dividend_type')}</th>
                  <th style={{ width: "12%" }} className="text-center text-white">{t('number_order_share')}</th>
                  <th style={{ width: "12%" }} className="text-center text-white">{t('dividend')}</th>
                  <th style={{ width: "12%" }} className="text-center text-white">{t('amount')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
                <tr>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                  <td className="text-end">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPayment;
