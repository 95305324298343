/** @format */

import React, { useRef, useState, useEffect } from "react";
import {
  TitleWrap,
} from "../../components";

const TeamGeneralInformation = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const [lang, setLang] = useState();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  }, []);

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{(lang === 'th') ? 'ข้อมูลทั่วไป' : 'General Information'}</h1>
          </div>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-12 mb-30">
            <table className="table-generalux">
              <tbody>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ชื่อบริษัท' : 'Company Name'} </td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? 'บริษัท ชีส ดิจิตอล เน็ตเวิร์ค จำกัด' : 'Cheese Digital Network Company Limited'}
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'เลขทะเบียนบริษัท' : 'Company Registration Number'}</td>
                  <td className="td-generalux-details">0105547092109</td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ลักษณะการประกอบธุรกิจ' : 'Type of Business'}</td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? 'บริการดิจิทัลคอนเทนต์ บริการพัฒนาระบบเทคโนโลยีสารสนเทศ และบริการด้านงานโฆษณา ออนไลน์ทุกประเภท' : 'Provider of digital content services, digital solution services and all types of online advertising services.'}
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ที่ตั้งสำนักงานใหญ่' : 'Location of Head Office'}</td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? 'เลขที่ 2521/55-56 ถนนลาดพร้าว แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กรุงเทพมหานคร 10310' : '2521/55-56, Ladprao Road,  Khongchaokhunsing Sub-district, '}
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'โทรศัพท์' : 'Phone'}</td>
                  <td className="td-generalux-details">(66) 2 530 0185-6 </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'โทรสาร' : 'Fax'}</td>
                  <td className="td-generalux-details">(66) 2 530 0172</td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'เว็ปไซต์ (URL)' : 'Website (URL)'}</td>
                  <td className="td-generalux-details">
                    https://www.cheesediginet.com
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ทุนจดทะเบียน' : 'Registered Capital'}</td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? '50,000,000 บาท (ห้าสิบล้านบาท) ณ วันที่ 31 มีนาคม 2566' : '50,000,000 Baht (Fifty Million Baht) as of March 31, 2023'}
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ทุนชำระแล้ว' : 'Paid-up Capital'}</td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? '50,000,000 บาท (ห้าสิบล้านบาท)' : '50,000,000 Baht (Fifty Million Baht)'}
                    {" "}
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">
                    {(lang === 'th') ? 'มูลค่าหุ้นที่ตราไว้ต่อหุ้น' : 'Par value per share'}

                  </td>
                  <td className="td-generalux-details">
                    {(lang === 'th') ? '100 บาท (หนึ่งร้อยบาท)' : '100 Baht'}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              <div className="col-12">
                <p className="team-name board-name">{(lang === 'th') ? 'บุคคลอ้างอิง' : 'Information of Other References'}</p>
              </div>
            </div>
            <table className="table-generalux">
              <tbody>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ผู้สอบบัญชี' : 'Auditor'}</td>
                  <td className="td-generalux-details">
                    <p>{(lang === 'th') ? 'นายปิยะ ชัยพฤกษ์มาลากร ผู้สอบบัญชีรับอนุญาตเลขที่ 7544 หรือ' : 'Mr. Piya Chaipruckmalakarn, Certified Public Accountant No. 5730 or'} </p>
                    <p>{(lang === 'th') ? 'นางสาวศิริวรรณ สุรเทพินทร์ ผู้สอบบัญชีรับอนุญาตเลขที่ 4640 หรือ' : 'Ms. Siriwan Suratepin, Certified Public Accountant No. 5730 or'}</p>
                    <p>{(lang === 'th') ? 'นายสำราญ แตงฉ่ำ ผู้สอบบัญชีรับอนุญาตเลขที่ 8021 หรือ' : 'Mr. Samran Taengcham, Certified Public Accountant No. 5730 or'}</p>
                    <p>{(lang === 'th') ? 'นางสาวณัฐธีรา พงษ์พินิจภิญโญ ผู้สอบบัญชีรับอนุญาตเลขที่ 7362' : 'Ms. Nuttira Pongpinitpinyo, Certified Public Accountant No. 5730'}</p>
                    <p>{(lang === 'th') ? 'บริษัท สำนักงาน อีวาย จำกัด' : 'EY Office Company Limited'}</p>
                    <p>{(lang === 'th') ? 'เลขที่ 193/136-137 อาคารเลครัชดา ชั้น 33 ถนนรัชดาภิเษก แขวงคลองเตย เขตคลองเตย กรุงเทพมหานคร 10110' : 'No. 193/136-137 Lake Ratchada Building, 33rd Floor, Ratchadaphisek Road, Khlong Toei Subdistrict, Khlong Toei District, Bangkok 10110'}</p>
                    <p>{(lang === 'th') ? 'โทรศัพท์' : 'Phone'} (66) 2 264 9090</p>
                    <p>{(lang === 'th') ? 'โทรสาร' : 'Fax'} (66) 2 264 0789</p>
                  </td>
                </tr>

                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ที่ปรึกษากฎหมาย' : 'Legal Advisor'}</td>
                  <td className="td-generalux-details">
                    <p>{(lang === 'th') ? 'สำนักงานกฎหมายสากล สยาม พรีเมียร์ จำกัด' : 'Siam Premier International Law Office Limited'}</p>
                    <p>{(lang === 'th') ? 'อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์ ชั้น 26 เลขที่ 999/9 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330' : 'The Offices at Central World, 26th Floor, 999/9 Rama 1 Road, Pathumwan, Bangkok 10330'}</p>
                    <p>{(lang === 'th') ? 'โทรศัพท์' : 'Phone'} (66) 2 646 1888</p> <p>{(lang === 'th') ? 'โทรสาร' : 'Fax'} (66) 2 646 1919</p>
                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th') ? 'ที่ปรึกษาทางการเงิน' : 'Financial Advisor'}</td>
                  <td className="td-generalux-details">
                    <p>{(lang === 'th') ? 'บริษัท แคปปิตอล วัน พาร์ทเนอร์ จำกัด' : 'Capital One Partners Company Limited'}</p>
                    <p>{(lang === 'th') ? 'เลขที่ 87/2 อาคารซีอาร์ซี ออลซีซั่นส์ เพลส ชั้น 12 ถนนวิทยุ แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330' : 'No. 87/2 CRC Tower, All Seasons Place, 12th Floor, Wireless Road, Lumpini, Pathumwan, Bangkok 10330'}</p>
                    <p>{(lang === 'th') ? 'โทรศัพท์' : 'Phone'} (66) 2 687 7162</p>
                    <p>{(lang === 'th') ? 'โทรสาร' : 'Fax'} (66) 2 687 7140</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamGeneralInformation;
