/** @format */

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { TitleWrap, SecSubTitle, SecTitle } from "../../components";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const TeamSignificantFinancial = ({ ...restProps }) => {
    const [lang, setLang] = useState();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });
    const settings = {
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div {...restProps}>
            <div className="bg-black pb-100px">
                <div className="container bg-white p-4">
                    {/* <TitleWrap className="text-center">
                        <div class="d-flex justify-content-center pt-60px">
                            <h1 class="title-line">Significant Financial</h1>
                        </div>
                    </TitleWrap> */}

                    {/* /// */}
                    <div className="row pt-4">
                        <div className="col-6">
                            <p className="team-name board-name">{t('significant_financial_data')}</p>
                        </div>
                        <div className="col-6 d-flex justify-content-end align-items-end">
                            <p className="text-16">({t('unit')} : {t('baht')})</p>
                        </div>
                    </div>
                    <table className="table-significant-financial">
                        <thead className="thead-significant-financial">
                            <tr>
                                <th></th>
                                <th className="text-center text-white">2565</th>
                                <th className="text-center text-white">2564</th>
                            </tr>
                        </thead>
                        <thead className="thead-blueux">
                            <tr>
                                <th className="">{t('financial_status')}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('current_assets')}</td>
                                <td className="text-end">97,942,687</td>
                                <td className="text-end">251,810,785</td>
                            </tr>
                            <tr>
                                <td>{t('non_current_assets')}</td>
                                <td className="text-end">55,737,586</td>
                                <td className="text-end">57,728,245</td>
                            </tr>
                            <tr>
                                <td>{t('total_assets')}</td>
                                <td className="text-end">153,680,273</td>
                                <td className="text-end">309,539,030</td>
                            </tr>
                            <tr>
                                <td>{t('trade_and_other_payables')}</td>
                                <td className="text-end">21,499,462</td>
                                <td className="text-end">177,647,634</td>
                            </tr>
                            <tr>
                                <td>{t('liabilities')}</td>
                                <td className="text-end">54,854,271</td>
                                <td className="text-end">227,799,654</td>
                            </tr>
                            <tr>
                                <td>{t('capital')}</td>
                                <td className="text-end">50,000,000</td>
                                <td className="text-end">50,000,000</td>
                            </tr>
                            <tr>
                                <td>{t('shareholders_quity')}</td>
                                <td className="text-end">98,826,002</td>
                                <td className="text-end">81,739,376</td>
                            </tr>
                            <tr>
                                <td>{t('liabilities_shareholder')}</td>
                                <td className="text-end">153,680,273</td>
                                <td className="text-end">309,539,030</td>
                            </tr>
                        </tbody>
                        <thead className="thead-blueux">
                            <tr>
                                <th className="">{t('performance')}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('revenue_services')}</td>
                                <td className="text-end">183,169,288</td>
                                <td className="text-end">216,354,553</td>
                            </tr>
                            <tr>
                                <td>{t('Other_income')}</td>
                                <td className="text-end">362,053</td>
                                <td className="text-end">2,655,559</td>
                            </tr>
                            <tr>
                                <td>{t('revenue')}</td>
                                <td className="text-end">183,531,341</td>
                                <td className="text-end">219,010,112</td>
                            </tr>
                            <tr>
                                <td>{t('cost_services')}</td>
                                <td className="text-end">123,781,307</td>
                                <td className="text-end">129,983,679</td>
                            </tr>
                            <tr>
                                <td>{t('admin_exp')}</td>
                                <td className="text-end">33,486,444</td>
                                <td className="text-end">27,140,076</td>
                            </tr>
                            <tr>
                                <td>{t('gross_profit')}</td>
                                <td className="text-end">22,353,593</td>
                                <td className="text-end">55,091,382</td>
                            </tr>
                            <tr>
                                <td>{t('net_profit')}</td>
                                <td className="text-end">17,325,166</td>
                                <td className="text-end">41,909,491</td>
                            </tr>
                            <tr>
                                <td>{t('eps')}</td>
                                <td className="text-end">34.65</td>
                                <td className="text-end">83.82</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="row">
                        <div className="col-12">
                            <p className="team-name board-name">{t('significant_financial')} </p>
                        </div>
                    </div>
                    <table className="table-significant-financial">
                        <thead className="thead-blueux">
                            <tr>
                                <th className="">{t('financial_status')}</th>
                                <th className="text-center">{t('unit')}</th>
                                <th className="text-center">2565</th>
                                <th className="text-center">2564</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{t('current_ration')}</td>
                                <td className="text-center">{t('times')}</td>
                                <td className="text-end">3.22</td>
                                <td className="text-end">1.19</td>
                            </tr>
                            <tr>
                                <td>{t('quick_ration')}</td>
                                <td className="text-center">{t('times')}</td>
                                <td className="text-end">2.88</td>
                                <td className="text-end">1.18</td>
                            </tr>
                            <tr>
                                <td>{t('gross_profit')} </td>
                                <td className="text-center">%</td>
                                <td className="text-end">32.56</td>
                                <td className="text-end">40.65</td>
                            </tr>
                            <tr>
                                <td>{t('net_profit')} </td>
                                <td className="text-center">%</td>
                                <td className="text-end">9.44</td>
                                <td className="text-end">19.14</td>
                            </tr>

                            <tr>
                                <td>{t('roe')} </td>
                                <td className="text-center">%</td>
                                <td className="text-end">17.53</td>
                                <td className="text-end">51.27</td>
                            </tr>
                            <tr>
                                <td>{t('roa')} </td>
                                <td className="text-center">%</td>
                                <td className="text-end">11.27</td>
                                <td className="text-end">13.54</td>
                            </tr>

                            <tr>
                                <td>{t('de')} </td>
                                <td className="text-center">{t('times')}</td>
                                <td className="text-end">0.56</td>
                                <td className="text-end">2.79</td>
                            </tr>
                            <tr>
                                <td>{t('interest_de')}</td>
                                <td className="text-center">{t('times')}</td>
                                <td className="text-end">0.26</td>
                                <td className="text-end">0.45</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* //// */}
                </div>
            </div>
        </div>
    );
};

export default TeamSignificantFinancial;
