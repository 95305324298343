import React, { Fragment } from "react";
// import "../index.css";

import {
  Seo,
  ScrollTopBtn,
  Breadcrumb,
  Breadcrumb_Bannermodify,
} from "../components";
import {
  HeaderTwo,
  HeroOne,
  TeamSMS,
  FooterTwo,
  TeamSMSSolutionDetails,
} from "../containers";
import SMSMarketing from "../components/SMSMarketing/SMSMarketing";
import SMSContact from "../components/SMSMarketing/SMSContact";
import SMSHeader from "../components/SMSMarketing/SMSHeader";
// import "../index.css";

const SMSSolution = () => (
  <Fragment>
    <Seo title="SMS Solution" />
    <HeaderTwo />

    {/* เก่า */}
    {/* <Breadcrumb_Bannermodify
      pageName="SMS Solution"
      bgImage="im
      ages/sms/SMS-08.jpg"
    /> */}
    {/* <TeamSMS className="" /> */}

    {/* เก่า */}
    <SMSHeader />
    <SMSMarketing />

    {/* <TeamSMSSolutionDetails /> */}

    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default SMSSolution;
