import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamPayment } from "../containers";
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <Seo title={t('dividend_policy_payment_title')} />
      <HeaderTwo />
      <Breadcrumb pageName="Dividend Policy and Payment"  bgImage="images/miw/bar.jpg" />
      <TeamPayment className="" />
      <FooterTwo />
      <ScrollTopBtn />
    </Fragment>
  );
};
export default Payment;

