import React, { Fragment, useEffect, useState } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamPersonalDetailsExecutives } from "../containers";

const PersonalDetailsExecutives = () => {
    return (
        <Fragment>
            <Seo title="Executives" />
            <HeaderTwo />
            <Breadcrumb pageName="Executives" bgImage="/images/miw/bar_006.jpg" />
            <TeamPersonalDetailsExecutives />
            <FooterTwo />
            <ScrollTopBtn />
        </Fragment>
    );
};

export default PersonalDetailsExecutives;
