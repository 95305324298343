import React, { Fragment, useRef, useEffect, useState } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import {
  HeaderTwo,
  FooterTwo,
  BlogThree,
  BlogNews_Events,
} from "../containers";
import { TitleWrap } from "../components/Titles/Titles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGetNewsEvents from "../hooks/useGetNewsEvents";
import ModalImage from "react-modal-image";
const qs = require("qs");

const NewsEventsDetails = () => {
  const { DataNewsEventsById, loading, getNewsEventsById } = useGetNewsEvents();
  const { t, i18n } = useTranslation();
  const contactArea = useRef(null);
  const { id } = useParams();
  const [lang, setLang] = useState();
  // const noHoldingOfficeTh = 'ไม่มีการดำรงตำแหน่ง';
  // const noHoldingOfficeEn = 'No holding office';
  // const positionTh = 'แห่ง';
  // const positionEn = 'Position(s)';

  useEffect(() => {
    // getNewsEventsById(id);

    // getNewsEvents(`locale=${lang=='th'?'th-TH':lang}`);
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      //  getNewsEventsById(id,`locale=${localStorage.getItem('cheese_lang')=='th'?'th-TH':localStorage.getItem('cheese_lang')}`);
      // getNewsEventsById(id);
    } else {
      setLang("th");
      // getNewsEventsById(id,`locale='th-TH'`);
      // getNewsEventsById(id);
    }
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      top: targetOffset,
      behavior: "smooth",
    });
  };

  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      // getNewsEventsById(id,`locale=${localStorage.getItem('cheese_lang')=='th'?'th-TH':localStorage.getItem('cheese_lang')}`);
      // getNewsEventsById(id);
    } else {
      setLang("th");
      // getNewsEventsById(id,`locale='th-TH'`);
      // getNewsEventsById(id);
    }
  });

  useEffect(() => {
    const queries = qs.stringify(
      {
        filters: {
          id: {
            $eq: id,
          },
          // locale:{
          //     $eq: lang,
          // },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    getNewsEventsById(queries);
  }, []);
  // console.log('NewsEvents',DataNewsEventsById);

  return (
    <>
      <Fragment>
        {DataNewsEventsById?.data?.map((post) => (
          <>
            <Seo
              title={
                lang == "th"
                  ? post.attributes.news_events[0]?.Title_th
                  : post.attributes.news_events[0]?.Title_en
              }
            />
            <HeaderTwo />
            <Breadcrumb
              pageName="Event Details"
              bgImage="/images/events/events_d.jpg"
            />
            <div className="bg-our-clients">
              <div className="container">
                <TitleWrap className="text-center">
                  <div class="d-flex justify-content-center pt-60px">
                    {/* <h1 class="title-line">{t('companyevents_title_Health_Check_up_Program_2024')}</h1> */}
                    <h1 class="title-line">
                      {lang == "th"
                        ? post.attributes.news_events[0]?.Title_th
                        : post.attributes.news_events[0]?.Title_en}
                    </h1>
                  </div>
                </TitleWrap>
                <div className="our-team-section">
                  <div className="row mb-4">
                    <div className="col-12">
                      {/* <div className="d-flex flex-row">
                                        <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                        <div className="p-2 team-it-title">{post.attributes.title}</div>
                                    </div> */}
                      <div className="d-flex flex-row">
                        {/* <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div> */}
                        <div className="p-2 text-d-vision lh-36 text-black">
                          {lang == "th"
                            ? post.attributes.news_events[0]?.details_th
                            : post.attributes.news_events[0]?.details_en}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      {/* <TeamCompanyevents_title_Health_Check_up_Program_2024 className="" /> */}
                      <div class="container-fluid">
                        <div class="row">
                          <div class="card-columns">
                            {post.attributes.gallery.data.map((gallerys) => (
                              // console.log(gallerys)
                              <div class="card card-pin">
                                {/* <img class="card-img" src="https://images.unsplash.com/photo-1489743342057-3448cc7c3bb9?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=6d284a2efbca5f89528546307f7e7b87&auto=format&fit=crop&w=500&q=60" alt="Card image"> */}
                                {/* <img
                                                    className="card-img"
                                                    src={`https://api.cheesediginet.com/${gallerys.attributes.url}`}
                                                    alt={gallerys.attributes.alternativeText}
                                                  /> */}
                                <ModalImage
                                  className="card-img"
                                  small={`https://api.cheesediginet.com/${gallerys.attributes.url}`}
                                  large={`https://api.cheesediginet.com/${gallerys.attributes.url}`}
                                  alt={gallerys.attributes.alternativeText}
                                />
                                {/* <div class="overlay">
                                                  <h2 class="card-title title">Cool Title</h2>
                                                  <div class="more">
                                                    <a href="post.html">
                                                    <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i> More </a>
                                                  </div>
                                                </div> */}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterTwo />
            <ScrollTopBtn />
          </>
        ))}
      </Fragment>
    </>
  );
};

export default NewsEventsDetails;
