import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { Button, SecTitle, TitleWrap } from "../../components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SMSContact from "../../components/SMSMarketing/SMSContact";
import SMSMarketing from "../../components/SMSMarketing/SMSMarketing";

const TeamSMSSolutionDetails = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });

  console.log("restProps", size);
  var img = "../images/sms/SMS-05.jpg";

  if (id == "sms-marketing") {
    img = "../images/sms/SMS-03.jpg";
    if (size.x <= 1024) {
      img = "../images/sms/SMS-15_0.jpg";
    } else if (size.x <= 1280) {
      img = "../images/sms/SMS ipad-13_0.jpg";
    }
  } else {
    img = "../images/sms/SMS-05.jpg";
    if (size.x <= 1024) {
      img = "../images/sms/SMS-16_0.jpg";
    } else if (size.x <= 1280) {
      img = "../images/sms/SMS ipad-14_0.jpg";
    }
  }

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
  });

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
    scrollToElement(id);
  }, []);

  return (
    <div {...restProps} id={id}>
      {/* <img src="../images/sms/SMS-03.png" alt="ทดลองบริการส่ง SMS ฟรี" /> */}
      <SMSContact />
      {/* <img className="image-bd" src={img} alt="" /> */}
      {/* <div className="container mb-100px">
        <TitleWrap>
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">SMS Solution</h1>
          </div>
          <h2 className="text-d-vision pt-4">
            {t('sms_description')}
          </h2>
        </TitleWrap>
      </div> */}
      {/* <div className="bg-black"> */}
      {/* <div className="bg-white">
        <div className="container pb-100px">
          <div class="d-flex justify-content-center pt-60px">
            <h1 className="title-line text-black">
              SMS Services
            </h1>
          </div>
          <div className="row pt-4 ">
            <div className="col-lg-4 col-sm-12 p-4 text-center">
              <div className="sms-img-wrapper">
                <img src="images/sms/sms_01.png" alt="team-member" />
              </div>
              <h3 className="sms-h3 text-black">
                SMS<span> Marketing</span>
              </h3>
            </div>
            <div className="col-lg-4 col-sm-12 p-4 text-center">
              <Link to="/otp-service">
                <div className="sms-img-wrapper">
                  <img src="images/sms/sms_06.png" alt="team-member" />
                </div>
                <h3 className="sms-h3 text-black">
                  SMS OTP
                </h3>
              </Link>
            </div>

            <div className="col-lg-4 col-sm-12 p-4 text-center">
              <div className="sms-img-wrapper">
                <img src="images/sms/sms_02.png" alt="team-member" />
              </div>
              <h3 className="sms-h3 text-black">
                E-COUPON
              </h3>
            </div>

          </div>

          <div className="row pt-4">
            <div className="col-lg-4 col-sm-12 p-4 text-center">
              <div className="sms-img-wrapper">
                <img src="images/sms/sms_04.png" alt="team-member" />
              </div>
              <h3 className="sms-h3 text-black"  style={{"font-size": "26px"}}>
                SMS 2Way/<span>USSD</span>
              </h3>
            </div>
            <div className="col-lg-4 col-sm-12 p-4 text-center">
              <div className="sms-img-wrapper">
                <img src="images/sms/sms_05.png" alt="team-member" />
              </div>
              <h3 className="sms-h3 text-black">
                LBS<br></br> <span style={{"font-size": "26px",color: "black"}}>(Location Base SMS)</span>
              </h3>
            </div>

          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TeamSMSSolutionDetails;
