import React, { Fragment, useEffect } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderTwo, FooterTwo, TeamExecutives } from "../containers";
import useGetExecutives from '../hooks/useGetExecutives';
import { TitleWrap } from '../components/Titles/Titles'
import { useTranslation } from "react-i18next";
const qs = require('qs');

const Executives = () => {
    const { executives, loading, getExecutives } = useGetExecutives();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const queries = qs.stringify({
            filters: {
                Type: {
                    $eq: 'Executive',
                },
            },
        }, {
            encodeValuesOnly: true, // prettify URL
        });
        getExecutives(queries);
    }, []);

    return (
        <Fragment>
            <Seo title="Executives" />
            <HeaderTwo />
            <Breadcrumb pageName="Executives" bgImage="images/miw/bar_006.jpg" />
            <div className="bg-our-clients">
                <div className="container">
                    <TitleWrap className="text-center">
                        <div class="d-flex justify-content-center pt-60px">
                            <h1 class="title-line">{t('executive_title')}</h1>
                        </div>
                    </TitleWrap>
                    <div className="our-team-section">
                        <div className="container">
                            <div className="row">
                                {(!loading) ? executives?.data.map((element) => {
                                    return <TeamExecutives data={element.attributes} id={element.id} key={element.id} />
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
            <ScrollTopBtn />
        </Fragment>
    );
};

export default Executives;
