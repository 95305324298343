import axios from 'axios';
let baseDomain;
// if (process.env.REACT_APP_ENV === 'development') {
//     baseDomain = 'http://13.228.19.139:3005';
//     console.log(process.env.REACT_APP_ENV);
// } else {
    // console.log(process.env.REACT_APP_ENV);
    baseDomain = 'https://api.cheesediginet.com';
// }

export const customHeaders = {
    Accept: 'application/json',
};

export const baseUrl = `${baseDomain}`;
export const smsRegisterUrl = 'https://mysalepage.com/smsotp/service/api-sms-lp.php';

export default axios.create({
    baseUrl,
    headers: customHeaders,
});

export const serializeQuery = (query) => {
    return Object.keys(query)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
        )
        .join('&');
};