// NameNuT 20240423 

import axios from 'axios';
let baseDomain;
baseDomain = 'https://api.cheesediginet.com';

export const customHeaders = {
    Accept: 'application/json',
    Authorization:'Bearer 53dddc0950bfa5b30f3a093738ba31a06fe6041026dc5bcf0ebf94e83f78ee3bd6b31e4b60ffb7dd4e9f507c922a3840c538f1f5952663252e96cfcd8f3c20a32e8c07f3078d9ea140eae45aa1cf07a488fd678160e78d23e6d28b6d129c581d003727f7109f79fb15c4dcb49d6fa0c7e7d6f913941579670346398568bac0e5'
};
export const baseUrl = `${baseDomain}`;

// axios.defaults.headers.common['Authorization'] = '53dddc0950bfa5b30f3a093738ba31a06fe6041026dc5bcf0ebf94e83f78ee3bd6b31e4b60ffb7dd4e9f507c922a3840c538f1f5952663252e96cfcd8f3c20a32e8c07f3078d9ea140eae45aa1cf07a488fd678160e78d23e6d28b6d129c581d003727f7109f79fb15c4dcb49d6fa0c7e7d6f913941579670346398568bac0e5';

export default axios.create({
    baseUrl,
    headers: customHeaders,
    
});

export const serializeQuery = (query) => {
    return Object.keys(query)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
        )
        .join('&');
};