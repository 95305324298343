import React, { useRef, useEffect } from "react";
import {
  Button,
  ImageBoxOne,
  SecTitle,
  SecSubTitle,
  InfoMedia,
} from "../../components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

const AboutOne = ({ className }) => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const about = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".image-first-section"),
      {
        x: -200
      },
      {
        x: 10,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: element.querySelector(".first"),
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      }
    );

    gsap.from('.second', {
      scrollTrigger: '.second',
      y: 200,
      duration: 1,
      transformOrigin: "bottom center",
      ease: "none",
    });
  }, []);

  return (
    <div ref={ref} className={`position-relative ${className || ""}`}>
      <div className="about-shape1 d-none d-xl-block">{/* Cheese */}</div>
      <div className="container z-index-common">
        <div className="row gx-60">
          <div className="col-lg-6 col-xl-5 mb-50 mt-110 mb-lg-0 first">
            <img className="image-first-section" src="images/miw/home-1.png" />
          </div>
          <div ref={about} className="col-lg-6 col-xl-7 align-self-center mt-5 second">
            <SecSubTitle className="text-d-vision">
              <i className="fas fa-bring-forward" />
              {t('about_us_title')}
            </SecSubTitle>
            <SecTitle className="text-title-vision ">
              {t('about_us_intro')}
            </SecTitle>
            <p style={{ whiteSpace: 'break-spaces' }} className="mb-4 mt-1 pb-3 text-d">
              {t('about_us_description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
