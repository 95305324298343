import React from "react";

const TestimonialBoxOne = ({image, text, name, designation}) => (
  <div className="testi-style1 text-center">
    <img className="w-70" src={image} alt="Blog Author" />
    {/* <div className="testi-icon"><i className={icon}/></div> */}
    <div className="testi-style-apn">
      <h3 className="text-h p-0 m-0 text-white">{name}</h3>
    </div>
    <p className="text-d p-0 m-0 text-white">{text}</p>
    {/*  <h3 className="testi-name h6">{name}</h3>
   <div className="testi-degi">{designation}</div>  */}
  </div>
);

export default TestimonialBoxOne;
