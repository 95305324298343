import DevCheeseService, { baseUrl } from "./DevCheeseService";

class NewsEventsService {
    async getNewsEvents(params) {
        // console.log(params)
        // console.log(`${baseUrl}/api/news-and-events-plural?${params}&populate=*&populate[author]=*`)
        const response = await DevCheeseService.get(`${baseUrl}/api/news-and-events-plural?${params}&populate=*&sort[0]=row_number:desc`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getNewsEventsById(payload) {
                    // https://api.cheesediginet.com/api/news-and-events-plural/?filters[id][$eq]=2&populate=*
        const response = await DevCheeseService.get(`${baseUrl}/api/news-and-events-plural/?${payload}&populate=*&sort[0]=row_number:desc`)
        // const response = await DevCheeseService.get(`${baseUrl}/api/news-and-events-plural/${id}?${payload}&populate=*`)
            .then((response) => {
                return response.data;
            }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}
// 
export default new NewsEventsService();