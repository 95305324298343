import React, { useRef, useEffect, useState } from "react";

const TeamOrganizationChart = ({ ...restProps }) => {
    const contactArea = useRef(null);
    const [lang, setLang] = useState();

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            behavior: "smooth",
            top: targetOffset,
        });
    };

    window.addEventListener("storage", () => {
        if (localStorage.getItem("cheese_lang")) {
            setLang(localStorage.getItem("cheese_lang"));
        } else {
            setLang("th");
        }
    });

    useEffect(() => {
        if (localStorage.getItem("cheese_lang")) {
            setLang(localStorage.getItem("cheese_lang"));
        } else {
            setLang("th");
        }
    }, []);

    return (
        <div {...restProps}>
            <div className="">
                <img className="w-100" src={lang === 'th' ? 'images/miw/organization-chart-th.jpg' : 'images/miw/organization-chart-en.jpg'} alt="team-member" />
            </div>
        </div>
    );
};

export default TeamOrganizationChart;
