// import React from 'react';
import {Link} from 'react-router-dom';
import {Blog, Pagination} from '../../components/';
// import useGetExecutives from '../hooks/useGetExecutives';

/** @format */

import React, { useRef, useEffect, useState } from "react";
import { InfoMedia, TitleWrap } from "../../components";
import { useParams } from "react-router-dom";
import useGetNewsEvents from '../../hooks/useGetNewsEvents';
import { baseUrl } from "../../services/Environment";
import moment from 'moment';
// import moment from 'moment-timezone';
import { mapMonth } from "../../utils/utils";
import { useTranslation } from "react-i18next";

// blog Two data
import blogData from '../../data/blog.json'; 

// NewsEventsService
// const BlogThree = ({...restProps})=> (
//   <div {...restProps}>   
//     <div className="container">
//       <div className="row">
//         {blogData.map(post => (
//           <div className="col-md-6 col-lg-4" key={post.id}>
//             <Blog className="blog-style1">
//               <Blog.Image path="/blog-details" src={post.featureImage} />
//               <Blog.Body>
//                 <Blog.Meta>
//                   <Link to="/blog"><i className="far fa-calendar"/>{post.date}</Link>
//                   <Link to="/blog"><i className="fal fa-user"/>{post.authorName}</Link>
//                 </Blog.Meta>
//                 <Blog.Title className="h5" path="/blog-details">{post.title}</Blog.Title>
//                 <Link to="/blog-details" className="link-btn">Read Details <i className="far fa-arrow-right"></i></Link>
//               </Blog.Body>
//             </Blog>
//           </div>
//         ))} 
//       </div>      
//       <div className="pt-20 text-center">
//         <Pagination />
//       </div>
//     </div>
//   </div>
// );

// export default BlogThree;

const BlogThree = () => {
    const { NewsEvents, loading, getNewsEventsById,getNewsEvents} = useGetNewsEvents();
    const { t, i18n } = useTranslation();
    const contactArea = useRef(null);
    const { id } = useParams();
    const [lang, setLang] = useState();
    // const noHoldingOfficeTh = 'ไม่มีการดำรงตำแหน่ง';
    // const noHoldingOfficeEn = 'No holding office';
    // const positionTh = 'แห่ง';
    // const positionEn = 'Position(s)';

    useEffect(() => {
        // getNewsEventsById(id);
        
        // getNewsEvents(`locale=${lang=='th'?'th-TH':lang}`);
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
             getNewsEvents(`locale=${localStorage.getItem('cheese_lang')=='th'?'th-TH':localStorage.getItem('cheese_lang')}`);
        } else {
            setLang('th');
            getNewsEvents(`locale='th-TH'`);
        }
    }, []);

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            top: targetOffset,
            behavior: "smooth",
        });
    };

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
            getNewsEvents(`locale=${localStorage.getItem('cheese_lang')=='th'?'th-TH':localStorage.getItem('cheese_lang')}`);
        } else {
            setLang('th');
            getNewsEvents(`locale='th-TH'`);
        }
        
    });

    return (
        <>
          <div className="row">
            {/* {(!loading) ? NewsEvents?.data.map((post) => { */}
             

            {NewsEvents?.data.map(post => (
              //  var res = post.attributes;
              <div className="col-md-6 col-lg-4" key={post.id}>
                <Blog className="blog-style1">
                  {/* <Blog className="blog-style1" style={{'background-color': 'whitesmoke'}}></Blog> */}
                  <Blog.Image path={`/news-events-details/`+post.id} src={`https://api.cheesediginet.com/`+post.attributes.image_header.data.attributes.url} />
                  <Blog.Body>
                    {/* <Blog.Meta>
                      <Link to="#"><i className="far fa-calendar"/>{moment(post.attributes.publishedAt).format("DD-MM-YYYY HH:mm")}</Link>
                      <Link to="#"><i className="fal fa-user"/>Admin</Link>
                    </Blog.Meta> */}
                    <Blog.Title className="h5" path={`/news-events-details/`+post.id}>{(lang=='th'?post.attributes.news_events[0]?.Title_th:post.attributes.news_events[0]?.Title_en)}</Blog.Title>
                    <Link to={`/news-events-details/`+post.id} className="link-btn">Read Details <i className="far fa-arrow-right"></i></Link>
                  </Blog.Body>
                </Blog>
              </div>
   
            ))}
            {/* }) : null} */}
          </div>      
          {/* <div className="pt-20 text-center">
            <Pagination />
          </div> */}
        </>
    );
};

export default BlogThree;
