import { useState, useEffect } from "react";
import ExecutiveService from "../services/ExecutiveService";

export default function useGetExecutives() {
    const [loading, setLoading] = useState(false);
    const [executives, setExecutives] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [lang, setLang] = useState();

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return {
        loading,
        executives,
        executive,
        setExecutives: (payload) => {
            setExecutives(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getExecutives: async (payload) => {
            setLoading(true);
            let responseData;
            if (payload) {
                responseData = await ExecutiveService.getExecutives(payload);
            } else {
                const queries = {
                    _limit: 12,
                };
                responseData = await ExecutiveService.getExecutives(queries);
            }
            if (responseData) {
                setExecutives(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        },

        getExecutiveById: async (payload) => {
            setLoading(true);
            const responseData = await ExecutiveService.getExecutiveById(payload);
            if (responseData) {
                setExecutive(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}