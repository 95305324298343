import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import {baseUrl} from "../../services/Environment";

const TeamExecutives = (props) => {
  const [lang, setLang] = useState();
  const {data, id} = props;
  const {Title_th, Title_en, Firstname_th, Firstname_en, Lastname_th, Lastname_en, Position_th, Position_en, Profile_image} = data;

  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
  });

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <Link to={`/personal-details-executives/${id}`}>
          <div className="container-bd">
            <div className="frame-bd">
              <div className="image-stack">
                <div className="image-stack__item image-stack__item--top">
                  <img
                    className="image-bd"
                    src="images/miw/board-directors/frame-info.png"
                    alt=""
                  />
                </div>
                <div className="image-stack__item image-stack__item--bottom">
                  <img
                    className="image-bd"
                    src={`${baseUrl}${Profile_image.data.attributes.url}`}
                    alt=""
                  />
                </div>
              </div> 
           
              <div className="frame-bd-display">
                <h3 className="name">
                  {lang === "th"
                    ? `${Title_th} ${Firstname_th} `
                    : `${Title_en} ${Firstname_en} `}
                </h3>
                <h3 className="surname">
                  {lang === "th" ? `${Lastname_th}` : `${Lastname_en}`}
                </h3>
                <div style={{ overflow: 'auto', height: '110px' }}>
                  {(lang === "th" ? Position_th : Position_en)?.map(
                    (position, index) => {
                      return (
                        <div key={index}>
                          <div className="frame-bd-txt">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="currentColor"
                              className="bi bi-circle-fill icon-dit-circle me-2"
                              viewBox="0 0 16 16"
                            >
                              <circle cx="8" cy="8" r="8" />
                            </svg>
                            <span>{position.Description}</span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default TeamExecutives;
