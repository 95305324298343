import { useState, useEffect } from "react";
import BoardChatersPolicyService from "../services/BoardChatersPolicyService";

export default function useGetBoardChartersPolicy() {
    const [loading, setLoading] = useState(false);
    const [policy, setPolicy] = useState(null);
    const [lang, setLang] = useState();

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return {
        policy,
        loading,

        setLoading: (payload) => {
            setLoading(payload);
        },

        getBoardChatersPolicy: async (payload) => {
            setLoading(true);
            const responseData = await BoardChatersPolicyService.getBoardChatersPolicy(payload);
            if (responseData) {
                setPolicy(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}