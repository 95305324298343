import React, { useRef, useState, useEffect } from "react";
import { Button, SecTitle, TitleWrap } from "../../components";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { smsRegisterUrl } from "../../services/Environment";

const TeamOTPService = ({ ...restProps }) => {
  const [isMobile, setIsMobile] = useState(false);
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState(false);
  const [inputs, setInputs] = useState({});

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  // Submit
  const formHandle = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    const errors = validate(inputs);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        cmd: 'regis_sms', // Default value
        oName: inputs.name,
        oEmail: inputs.email,
        oTel: inputs.phone,
      }

      formData.append('data', JSON.stringify(data));
      await fetch(smsRegisterUrl, {
        method: 'post',
        body: formData
      }).then(() => {
        setInputs({});

        toast.success('Submit success', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }).catch(error => {
        throw new Error(error);
      });
    }
  };

  // Validate
  const validate = (data) => {
    const errors = {};

    if (!data.name?.trim()) {
      errors.name = 'Name is required';
    }

    if (!data.email?.trim()) {
      errors.email = 'Email is required';
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email))
        errors.email = 'Invalid email';
    }

    if (!data.phone?.trim()) {
      errors.phone = 'Phone is required';
    }

    return errors;
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value);
    setInputs(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    const checkIsMobile = () => {
      const isMobile = window.matchMedia('(max-width: 767px)').matches;
      setIsMobile(isMobile);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, [isMobile]);

  return (
    <div>
      <div className="bg-otp">
        <img className="w-100" src="images/miw/sms_otp.jpg" />
        <div className="container">
          <TitleWrap className="text-center">
            <div class="d-flex justify-content-center pt-60px">
              {(isMobile == true)?
                <h1 class="title-otp-service break-word">
                  {t('otp')}<br></br><span >{t('one_time_service')}</span><br></br>{t('service')}
                </h1>
              :
                <h1 class="title-otp-service break-word">
                  {t('otp')} <span>{t('one_time_service')}</span> {t('service')}
                </h1>}
            </div>
            {/* <SecTitle className="text-capitalize text-d-vision pt-4 text-white">{t('sms_description_otp1')}<br></br>{t('sms_description_otp2')}<br></br>{t('sms_description_otp3')}</SecTitle> */}
            <SecTitle className="text-capitalize text-d-vision pt-4 text-white">{t('sms_description_otp')}</SecTitle>
          </TitleWrap>
        </div>
        <div className="row mb-30 mr-0">
          <div className="col-lg-3 border-otp-service"></div>
          <div className="col-lg-6"></div>
          <div className="col-lg-3 border-otp-service"></div>
        </div>
        <div className="container">
          <TitleWrap className="text-center">
            <div class="d-flex justify-content-center pt-30px">
              <h1 class="title-otp-service2">
                {t('otp_why')} <span>Cheese</span> {t('sms_otp')}
              </h1>
            </div>
          </TitleWrap>
          <div className="row pb-100px">
          {/* <div className="row mb-30 pb-100px"> */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-01.png" />
                </div>
                <div className="text-white text-center text-otp">{t('otp_why_1')}</div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-02.png" />
                </div>
                <div className="text-white text-center text-otp">
                  {t('otp_why_2')}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-03.png" />
                </div>
                <div className="text-white text-center text-otp">{t('otp_why_3')}</div>
              </div>
            </div>
          {/* </div>
          <div className="row pb-100px"> */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-04.png" />
                </div>
                <div className="text-white text-center text-otp">{t('otp_why_4')}</div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-05.png" />
                </div>
                <div className="text-white text-center text-otp">{t('otp_why_5')}</div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div class="d-flex flex-column">
                <div className="p-2">
                  <img className="img-otp" src="images/miw/otp-06.png" />
                </div>
                <div className="text-white text-center text-otp">{t('otp_why_6')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-otp2 pb-4">
        <div className="container">
          <TitleWrap className="text-center">
            <div class="d-flex flex-column justify-content-center pt-60px line-otp">
              <h1 class="title-otp-service3">
                {t('otp_contact')} / <span> {t('otp_free_trail')} /</span>
              </h1>
              <h1 class="title-otp-service4">
                {t('otp_consult')} <span> {t('otp_free')}</span>
              </h1>
            </div>
          </TitleWrap>
          <div className="row mb-30">
            <form className="form-inner">
              <div className="col-lg-6 offset-lg-3">
                <div className="row gx-20">
                  <div className="col-md-12 form-group">
                    <input type="text" name="name" className="form-control" placeholder="Your Name" onChange={handleChange}
                      value={inputs.name || ""} />
                    <i className="fal fa-user"></i>
                    {errors.name && <span className="text-danger">{errors.name}</span>}
                  </div>
                  <div className="col-md-12 form-group">
                    <input type="email" name="email" className="form-control" placeholder="Your Email" onChange={handleChange}
                      value={inputs.email || ""} />
                    <i className="fal fa-envelope"></i>
                    {errors.email && <span className="text-danger">{errors.email}</span>}
                  </div>
                  <div className="col-md-12 form-group">
                    <input type="text" name="phone" className="form-control" placeholder="Your Phone" onChange={handleChange}
                      value={inputs.phone || ""} />
                    <i className="fal fa-phone"></i>
                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-2">
                <div className="row">
                  <div className="col-6">
                    <Button className="btn-otp" onclick={formHandle}>{t('otp_register')}</Button>
                  </div>
                  <div className="col-6">
                    <a href="tel:+66612462951" className="vs-btn btn-otp">
                      <i className="fal fa-phone-alt icon-orange pe-2" />
                      {t('otp_call')}!
                    </a>
                  </div>
                </div>
                <ToastContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
      <img className="w-100" src="images/miw/footer-01.jpg" />
    </div>
  );
};

export default TeamOTPService;
