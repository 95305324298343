import React, { Fragment } from "react";
import { Seo, ScrollTopBtn } from "../components";
import {
  HeaderTwo,
  HeroOne,
  FeatureOne,
  AboutOne,
  ServiceOne,
  CounterOne,
  TeamOne,
  FaqOne,
  AboutTwo,
  CtaOne,
  ProcessOne,
  TestimonialOne,
  BrandOne,
  BlogTwo,
  FooterOne,
  FooterTwo,
} from "../containers";
import Modal from "../components/SMSMarketing/Modal";

const HomeOne = () => (
  <Fragment>
    <Seo title="Cheese Digital Network" />
    {/* <Modal /> */}
    <HeaderTwo />
    {/* Header */}
    <HeroOne />
    {/* ./ Header */}
    {/* <div className="background-image" style={{backgroundImage: "url(images/miw/bg-content_01.jpg)"}}></div> */}
    <div className="background-image">
      <AboutOne className="space-bottom" />
    </div>
    <TestimonialOne className="space-top space-extra-bottom bg-black" />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default HomeOne;
