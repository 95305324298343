/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Button, FormThree, InfoMedia, ProgressOne, SecSubTitle, SecTitle, TitleWrap, InfoMediaDownload } from "../../components";
import { useTranslation } from "react-i18next";
import useFinancialStatement from "../../hooks/useFinancialStatement";
import { baseUrl } from "../../services/Environment";
import { Link } from "react-router-dom";
const qs = require('qs');

const TeamFinancialStatements = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const { t, i18n } = useTranslation();
  const { years, financialStatement, loading, getFinancialStatementYears, getFinancialStatementByYear } = useFinancialStatement();
  console.log(years);
  const [currentYear, setCurrentYear] = useState('');
  const [lang, setLang] = useState();

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }

    const queries = qs.stringify({
      sort: {
        Year: 'desc'
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getFinancialStatementYears(queries);

    const queryByYear = qs.stringify({
      filters: {
        Year: {
          $eq: years?.data[0]?.attributes.Year
        }
      },
      sort: {
        Year: 'desc'
      },
      populate: {
        Details: {
          populate: '*',
        },
        Quaters: {
          populate: '*',
        }
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getFinancialStatementByYear(queryByYear);
  }, []);

  const onChangeYear = (event) => {
    setCurrentYear(event.target.value);
    // event.preventDefault();
    const queries = qs.stringify({
      filters: {
        Year: {
          $eq: event.target.value
        }
      },
      populate: {
        Details: {
          populate: '*',
        },
        Quaters: {
          populate: '*',
        }
      },
    }, {
      encodeValuesOnly: true, // prettify URL
    });
    getFinancialStatementByYear(queries);
  }

  return (
    <div>
      {years?.data.length === 0 ? <div className="container" style={{ height: '500px' }}> <div className="h-100 d-flex align-items-center justify-content-center">{t('data_not_found')}</div></div> :
        <div className="container mb-100px">
          <div className="row mb-100px">
            <div className="col-lg-12 mb-50 mt-110 mb-lg-0">
              <SecTitle className="text-title-vision">{t('financial_statement_title')} {currentYear ? currentYear : years?.data[0]?.attributes.Year}</SecTitle>
              <div className="row">
                <div className="col-lg-12">
                  <table className="table-fin-sta">
                    <tbody>
                      {(!loading && financialStatement?.data[0]?.attributes.Details?.length > 0) ? financialStatement?.data[0].attributes.Details?.map((element, index) => {
                        return <tr>
                          <td style={{ width: "90%" }}>{lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'}</td>
                          <td style={{ width: "90%" }} className="text-center">
                            <Link to={element.File ? `${baseUrl}${element.File?.data.attributes?.url}` : '#'}> <img className="icon-dl-fi" src="images/miw/download.png" /></Link>
                          </td>
                        </tr>
                      }) : ''}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div class="d-flex flex-row align-items-center">
                <div className="p-2 text-26 text-w500">{t('financial_statement_year')}</div>
                <div>
                  <select className="select-apn" style={{ width: "250px" }} onChange={onChangeYear} value={currentYear}>
                    {(!loading) ? years?.data.map((element, index) => {
                      return <option key={index} value={element?.attributes.Year}>{element?.attributes.Year}</option>
                    }) : null}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <table className="table-fin-sta">
                <thead className="thead-fin-sta">
                  <tr>
                    <th colSpan={2} className="text-center">
                      {currentYear ? currentYear : years?.data[0]?.attributes.Year}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(!loading && financialStatement?.data[0]?.attributes.Quaters?.length > 0) ? financialStatement?.data[0].attributes.Quaters?.map((element, index) => {
                    return <tr>
                      <td style={{ width: "90%" }}>{lang === 'th' ? element.Title_th : element.Title_en ? element.Title_en : '-'}</td>
                      <td style={{ width: "10%" }} className="text-center">
                        <Link to={element.File ? `${baseUrl}${element.File?.data.attributes?.url}` : '#'}> <img className="icon-dl-fi" src="images/miw/download.png" /></Link>
                      </td>
                    </tr>
                  }) : ''}
                </tbody>
              </table>
            </div>
          </div>
        </div>}
      <img className="w-100" src="images/miw/financial-statements_001.jpg" alt="Skill" />
    </div>
  );
};

export default TeamFinancialStatements;
