import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button } from "../../components";
import { gsap } from "gsap";
import { useMediaQuery } from 'react-responsive';

const HeroOne = () => {
  const [lang, setLang] = useState();
  let imageBannerOne = 'images/bg/bg-home-01.jpg'
  let imageBannerTwo = 'images/bg/bg-home-02.jpg'
  const isIpad = useMediaQuery({ query: `(max-width: 768px)` });
  const isMobile = useMediaQuery({ query: `(max-width: 425px)` });

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
        setLang(localStorage.getItem('cheese_lang'));
    } else {
        setLang("th");
    }
});

  if (isIpad) {
    imageBannerOne = 'images/bg/bg-home-ipad-01.jpg';
    imageBannerTwo = 'images/bg/bg-home-ipad-02.jpg';
  }

  if (isMobile) {
    imageBannerOne = 'images/bg/bg-home-mobile-01.jpg';
    imageBannerTwo = 'images/bg/bg-home-mobile-02.jpg';
  }

  const HEROCONTENT = [
    {
      image: imageBannerOne,
      title: (
        <>
          <span className="txt-yellow">DIGITAL</span>
          <br />
          <span className="txt-hero-subtitle-one">
            MARKETING SERVICES
            {/* <br /> */}
            {isIpad || isMobile ? <div className="divided"></div> : ''}
          </span>
        </>
      ),
      text_th: "เราเป็นผู้นำด้านเทคโนโลยี และมีความชำนาญในการวางกลยุทธ์ ทำการตลาดออนไลน์ รวมถึงการสร้าง และใช้เครื่องมือต่างๆ ผ่านช่องทางอิเล็กทรอนิกส์ เพื่อช่วยเหลือลูกค้าได้ผลลัพธ์เป็นไปตามเป้าหมายที่วางไว้",
      text_en: "We are the leader in technology and professional in strategic planning for online marketing services, including creating and using various tools through elctronic channels in order to help our customers achieve their goals.",
      btn1: { text: "About Us", path: "/about" },
      btn2: { text: "READ MORE", path: "/about" },
    },
    {
      image: imageBannerTwo,
      title: (
        <>
          <span className="txt-yellow">DIGITAL</span>
          <br />
          <span className="txt-hero-subtitle-two">
            SOLUTIONS
            {/* <br /> */}
            {isIpad || isMobile ? <div className="divided"></div> : ''}
          </span>
        </>
      ),
      text_th: "เรามุ่งพัฒนา Digital Solutions ให้บริการเทคโนโลยีสารสนเทศครบวงจรและทันสมัยทั้ง Software และ Hardware ตามความต้องการได้อย่างมีประสิทธิภาพ",
      text_en: "We aim develop Digital Solutions, providing total solutions with the most advanced technology services, both software and hardware as required efficiently.",
    },
  ];

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
        },
      }
    ],
  };

  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
    } else {
      setLang("th");
    }
    
    gsap.from('.text-banner', {
      scrollTrigger: '.text-banner',
      y: 200,
      duration: 1,
      transformOrigin: "bottom center",
      ease: "none",
    });
  })

  return (
    <Slider className="hero-layout1" {...settings}>
      {HEROCONTENT.map((slide, index) => (
        <div key={index}>
          <div className="hero-inner">
            {/* Option */}
            {/* <div id='stars'></div> */}
            {/* <div id='stars2'></div> */}
            {/* <div id='stars3'></div> */}
            <div class="lines">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
            <div className="hero-shape1 d-hidden"></div>
            <div className="hero-shape2 d-hidden"></div>
            <div className="hero-bg background-image" style={{ backgroundImage: `url(${slide.image})` }}></div>
            <div className="container">
              <div className={index === 0 ? 'hero-content' : 'hero-content-two'}>
                <div className="rowalign-items-center">
                  <div className="col-lg-6 offset-lg-6 col-xxl">
                    <div className={index === 0 ? 'hero-content' : 'hero-content-two'}>
                      <span className="hero-subtitle">{slide.subTitle}</span>
                      <h2 className={index === 0 ? 'hero-title' : 'hero-title-two'}>
                        {slide.title}
                      </h2>
                    </div>
                    <p className={index === 0 ? 'hero-text text-22 txt-hero-desc-one' : 'hero-text text-22 txt-hero-desc-two'}>{lang === 'th' ? slide.text_th : slide.text_en}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default HeroOne;
