import React, { useRef } from "react";
import {
  Button,
  FormThree,
  InfoMediaInvestor,
  InfoMediaDownloadFinancial,
  ProgressOne,
  SecSubTitle,
  SecTitle,
  TitleWrap,
} from "../../components";
import faqData from "../../data/investor.json";
import faqData2 from "../../data/investor.json";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

const TeamInvestorInfo = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const years = [];
  const { t, i18n } = useTranslation();
  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <TitleWrap className="text-center">
            <div class="d-flex justify-content-center pt-60px">
              <h1 class="title-line">ข้อมูลนำเสนอนักลงทุนและเว็บคาสต์</h1>
            </div>
          </TitleWrap>
          {years?.length === 0 ? (
            <div className="text-center">{t("data_not_found")}</div>
          ) : (
            <>
              <div className="row bg-room mb-75px">
                <div className="col-lg-4 text-center d-flex align-items-end">
                  <div className="d-flex flex-column">
                    <div className="hover02">
                      <figure>
                        <img
                          className="w-75 hover01"
                          src="images/miw/book-1.jpg"
                          alt="team-member"
                        />
                      </figure>
                    </div>
                    <div className="align">
                      <div className="grid">
                        <p className="manifesto-headline text-investor">2021</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-center d-flex align-items-end">
                  <div className="d-flex flex-column">
                    <div className="hover02">
                      <figure>
                        <img
                          className="w-100 hover01"
                          src="images/miw/book-1.jpg"
                          alt="team-member"
                        />
                      </figure>
                    </div>
                    <div className="align">
                      <div className="grid">
                        <p className="manifesto-headline text-investor">2021</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-center d-flex align-items-end">
                  <div className="d-flex flex-column">
                    <div className="hover02">
                      <figure>
                        <img
                          className="w-75 hover01"
                          src="images/miw/book-3.jpg"
                          alt="team-member"
                        />
                      </figure>
                    </div>
                    <div className="align">
                      <div className="grid">
                        <p className="manifesto-headline text-investor">
                          Corporation
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 ps-0 ms-0">
                  {/* <Accordion className="accordion-style1 layout2">
              <Accordion.Item>
                <Accordion.Header>ANALYST MEETING</Accordion.Header>
                <Accordion.Body>
                  <div className="project-box-investor">
                    <InfoMediaInvestor info="Name 1" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                    <InfoMediaInvestor info="Name 2" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

                  <Accordion className="accordion-style1 layout2">
                    <Accordion.Item>
                      <Accordion.Header>OPPORTUNITY DAY</Accordion.Header>
                      <Accordion.Body>
                        <div className="project-box-investor">
                          <InfoMediaInvestor
                            info="Name 1"
                            downloadPdfOne="1Q"
                            downloadPdfTwo="2Q"
                            downloadPdfThree="3Q"
                            downloadPdfFour="4Q"
                            downloadMp3One="1Q"
                            downloadMp3Two="2Q"
                            downloadMp3Three="3Q"
                            downloadMp3Four="4Q"
                          />
                          <InfoMediaInvestor
                            info="Name 2"
                            downloadPdfOne="1Q"
                            downloadPdfTwo="2Q"
                            downloadPdfThree="3Q"
                            downloadPdfFour="4Q"
                            downloadMp3One="1Q"
                            downloadMp3Two="2Q"
                            downloadMp3Three="3Q"
                            downloadMp3Four="4Q"
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {/*   <Accordion className="accordion-style1 layout2">
              <Accordion.Item>
                <Accordion.Header>WEBCAST</Accordion.Header>
                <Accordion.Body>
                  <div className="project-box-investor">
                    <InfoMediaInvestor info="Name 1" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                    <InfoMediaInvestor info="Name 2" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
                </div>
                <div className="col-6 pe-0 me-0">
                  <Accordion className="accordion-style1 layout2">
                    <Accordion.Item>
                      <Accordion.Header>ROADSHOW</Accordion.Header>
                      <Accordion.Body>
                        <div className="project-box-investor">
                          <InfoMediaInvestor
                            info="Name 1"
                            downloadPdfOne="1Q"
                            downloadPdfTwo="2Q"
                            downloadPdfThree="3Q"
                            downloadPdfFour="4Q"
                            downloadMp3One="1Q"
                            downloadMp3Two="2Q"
                            downloadMp3Three="3Q"
                            downloadMp3Four="4Q"
                          />
                          <InfoMediaInvestor
                            info="Name 2"
                            downloadPdfOne="1Q"
                            downloadPdfTwo="2Q"
                            downloadPdfThree="3Q"
                            downloadPdfFour="4Q"
                            downloadMp3One="1Q"
                            downloadMp3Two="2Q"
                            downloadMp3Three="3Q"
                            downloadMp3Four="4Q"
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/*  <Accordion className="accordion-style1 layout2">
              <Accordion.Item>
                <Accordion.Header>CORPORATE PRESENTATION</Accordion.Header>
                <Accordion.Body>
                  <div className="project-box-investor">
                    <InfoMediaInvestor info="Name 1" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                    <InfoMediaInvestor info="Name 2" downloadPdfOne="1Q" downloadPdfTwo="2Q" downloadPdfThree="3Q" downloadPdfFour="4Q" downloadMp3One="1Q" downloadMp3Two="2Q" downloadMp3Three="3Q" downloadMp3Four="4Q" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
                </div>
              </div>
            </>
          )}
        </TitleWrap>
      </div>
    </div>
  );
};

export default TeamInvestorInfo;
