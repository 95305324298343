import React, {Fragment} from "react";
import {Seo, Breadcrumb, ScrollTopBtn} from "../components";
import {HeaderTwo, FooterTwo, TeamShareholderMeeting} from "../containers";
const ShareholderMeeting = () => (
    <Fragment>
        <Seo title="Shareholders Meeting" />
        <HeaderTwo />
        <Breadcrumb pageName="Shareholders Meeting" bgImage="images/miw/bar.jpg" />
        <TeamShareholderMeeting className="" />
        <FooterTwo />
        <ScrollTopBtn />
    </Fragment>
);

export default ShareholderMeeting;