import React from "react";

function SMSLogo({ children }) {
  return (
    <div className="flex gap-3 items-start mt-0 sm:mb-2 lg:mb-4 ">
      {children}
    </div>
  );
}

export default SMSLogo;
