/** @format */

import React, { useRef, useState, useEffect } from "react";
import { SecTitle, TitleWrap } from "../../components";
import { useTranslation } from "react-i18next";

const TeamIT = ({ ...restProps }) => {
    const contactArea = useRef(null);
    const [lang, setLang] = useState();
    const { t, i18n } = useTranslation();

    const scrollHandler = (event) => {
        event.preventDefault();
        let targetOffset = contactArea.current.offsetTop - 200;
        window.scrollTo({
            behavior: "smooth",
            top: targetOffset,
        });
    };

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return (
        <div {...restProps}>
            <div className="container mb-100px">
                <TitleWrap className="text-center">
                    <div class="d-flex justify-content-center pt-60px">
                        <h1 className="title-line">IT Solution</h1>
                    </div>
                    <h2 className="d-flex justify-content-left text-d-vision pt-4" style={{"text-align": "start"}}>
                        {(lang === 'th') ? 'บริการทางด้าน IT สำหรับองค์กรขนาดใหญ่ เพื่อพัฒนาโซลูชั่นเฉพาะตัวสำหรับองค์กรนั้นๆ โดยบริษัทฯตระหนักดีถึงความต้องการและการแก้ปัญหาที่แตกต่างกันของแต่ละองค์กรและนำระบบเทคโนโลยีและสารสนเทศมาใช้เป็นเครื่องมือในการพัฒนาไปสู่คำตอบหรือทางแก้ เรามีบริการพัฒนาแบบครบวงจร อาทิ' 
                            : 'It is an IT service for large organizations to develop exclusive solutions. The Company is well aware of the different needs and solutions of each organization and uses technology and information systems as tools to develop business solutions We provide comprehensive IT solution services as follows;'}
                    </h2>
                </TitleWrap>
                <div className="row">
                    {/* <div className="col-lg-12 text-center">
                        <TitleWrap className="text-center">
                            <div class="d-flex justify-content-center">
                                <h1 className="title-line">{t('it_solution_subtitle')}</h1>
                            </div>
                        </TitleWrap>
                    </div> */}
                    <div className="row mb-4">
                        <div className="col-6">
                            <img src="images/miw/it_01.jpg" alt="team-member" />
                        </div>
                        <div className="col-6">
                            <img src="images/miw/it_02.jpg" alt="team-member" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Custom Software Development</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_1')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Mobile Application Development</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_2')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Web Application Development</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_3')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <img src="images/miw/it_03.jpg" alt="team-member" />
                        </div>
                        <div className="col-6">
                            <img src="images/miw/it_04.jpg" alt="team-member" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Enterprise Solutions</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_4')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Architecture Design</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_5')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">UX/UI Design</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_6')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <img src="images/miw/it_05.jpg" alt="team-member" />
                        </div>
                        <div className="col-6">
                            <img src="images/miw/it_06.jpg" alt="team-member" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">API Services & Solutions</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_7')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">Automated Testing</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_8')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="d-flex flex-row">
                                <div className="p-2 team-it-title"><i className="fas fa-minus"></i></div>
                                <div className="p-2 team-it-title">DevOps Engineering</div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="p-2 v-hidden team-it-title"><i class="fas fa-minus"></i></div>
                                <div className="p-2 text-d-vision lh-36 text-black">{t('it_solution_service_description_9')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamIT;
