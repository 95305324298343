import { useState, useEffect } from "react";
import ShareholderMeetingService from "../services/ShareholderMeetingService";

export default function useShareholderMeeting() {
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState(null);
    const [shareholderMeeting, setShareholderMeeting] = useState(null);
    const [lang, setLang] = useState();

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return {
        loading,
        years,
        shareholderMeeting,
        setYears: (payload) => {
            setYears(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getShareholderMeetingYears: async (payload) => {
            setLoading(true);
            let responseData;
            if (payload) {
                responseData = await ShareholderMeetingService.getShareholderMeetingYears(payload);
            } else {
                const queries = {
                    _limit: 12,
                };
                responseData = await ShareholderMeetingService.getShareholderMeetingYears(queries);
            }
            if (responseData) {
                setYears(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        },

        getShareholderMeetingByYear: async (payload) => {
            setLoading(true);
            const responseData = await ShareholderMeetingService.getShareholderMeetingByYear(payload);
            if (responseData) {
                setShareholderMeeting(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}