import { useState, useEffect } from "react";
import NewsEventsService from "../services/NewsEventsHooks";

export default function useGetNewsEvents() {
    const [loading, setLoading] = useState(false);
    const [NewsEvents, setNewsEvents] = useState(null);
    const [DataNewsEventsById, setNewsEventsById] = useState(null);
    const [lang, setLang] = useState();

    window.addEventListener('storage', () => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    });

    useEffect(() => {
        if (localStorage.getItem('cheese_lang')) {
            setLang(localStorage.getItem('cheese_lang'));
        } else {
            setLang('th');
        }
    }, []);

    return {
        loading,
        NewsEvents,
        DataNewsEventsById,
        setNewsEvents: (payload) => {
            setNewsEvents(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getNewsEvents: async (payload) => {
            setLoading(true);
            let responseData;
            if (payload) {
                responseData = await NewsEventsService.getNewsEvents(payload);
            } else {
                const queries = {
                    _limit: 12,
                    // increments: true,
                    // timestamps: true,
                    // draftAndPublish: true,
                    // privateAttributes: ["created_by", "created_at"],
                    // populateCreatorFields: true
                };
                responseData = await NewsEventsService.getNewsEvents(queries);
            }
            if (responseData) {
                setNewsEvents(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        },

        getNewsEventsById: async (payload) => {
            setLoading(true);
            const responseData = await NewsEventsService.getNewsEventsById(payload);
            if (responseData) {
                setNewsEventsById(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}