import React, { useRef } from "react";
import {
  Button,
  FormThree,
  InfoMedia,
  ProgressOne,
  SecSubTitle,
  SecTitle,
  TitleWrap,
  InfoMediaDownloadBook,
} from "../../components";
import { useTranslation } from "react-i18next";
const TeamPublications = ({ ...restProps }) => {
  const contactArea = useRef(null);
  const years = [];
  const { t, i18n } = useTranslation();
  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      behavior: "smooth",
      top: targetOffset,
    });
  };

  return (
    <div {...restProps}>
      <div className="container mb-100px">
        <TitleWrap className="text-center">
          <TitleWrap className="text-center">
            <div class="d-flex justify-content-center pt-60px">
              <h1 class="title-line">56-1 One Report</h1>
            </div>
          </TitleWrap>
          {years?.length === 0 ? (
            <div className="text-center">{t("data_not_found")}</div>
          ) : (
            <div className="row gx-60 mb-4 pb-1 gy-2 text-start justify-content-center justify-content-xl-start">
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-1.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                />
              </div>
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-2.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                  // titleTwo="ประจำปี 2563" imagePlayTwo="images/miw/iconPlayOnline.png" imageDownloadTwo="images/miw/iconDownload.png"
                />
              </div>
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-3.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                />
              </div>
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-2.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                  // titleTwo="ประจำปี 2563" imagePlayTwo="images/miw/iconPlayOnline.png" imageDownloadTwo="images/miw/iconDownload.png"
                />
              </div>
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-3.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                />
              </div>
              <div className="col-4 p-0 m-0">
                <InfoMediaDownloadBook
                  className="about-media"
                  image="images/miw/book-1.jpg"
                  titleOne="56-1 One Report"
                  imagePlayOne="images/miw/iconPlayOnline.png"
                  imageDownloadOne="images/miw/iconDownload.png"
                  // titleTwo="ประจำปี 2563" imagePlayTwo="images/miw/iconPlayOnline.png" imageDownloadTwo="images/miw/iconDownload.png"
                />
              </div>
            </div>
          )}
        </TitleWrap>
      </div>
    </div>
  );
};

export default TeamPublications;
