import React, { useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AboutSeven = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.from('.vision-section', {
      scrollTrigger: '.vision-section',
      x: -200,
      duration: 1,
      transformOrigin: "bottom center",
      ease: "none",
    });

    gsap.from('.mission-section', {
      scrollTrigger: '.mission-section',
      x: 200,
      duration: 1,
      transformOrigin: "bottom center",
      ease: "none",
    });

    gsap.from('.core-value-section', {
      scrollTrigger: '.core-value-section',
      y: 200,
      duration: 1,
      transformOrigin: "bottom center",
      ease: "none",
    });
  });

  return (
    <>
      <div className="background-image" style={{ backgroundImage: 'url(images/bg/bg-vision-01.jpg)' }}>
        <div className="container py-5">
          <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', margin: '0 -3rem' }}>
            <div className="pt-4 vision-section">
              <h1 className='m-0 vision-title'>VISION <span className='text-white vision-subtitle'>(วิสัยทัศน์)</span></h1>
              <p className='text-white' style={{ padding: '0 3rem', fontSize: '22px' }}>To create digital technology and bring long term value to customers through professional
                digital marketing solutions and to be Thailand's leading solution provider which offers
                digital solutions with the most advanced technology.</p>
              <div style={{ margin: '0 0 0 3rem', borderLeft: '10px solid var(--yellow-color)', padding: '0 0 0 1rem' }}>
                <p className="m-0 text-white">สร้างสรรค์เทคโนโลยีดิจิทัลและนำมูลค่าระยะยาวมาสู่ลูกค้าผ่านทางโซลูชั่น การตลาดดิจิทัลที่ขับเคลื่อนด้วยประสิทธิภาพ</p>
                <p className="m-0 text-white">และเป็นผู้ให้บริการออกแบบระบบเทคโนโลยีสารสนเทศชั้นนำของเมืองไทย</p>
                <p className="m-0 text-white">ดำเนินธุรกิจด้านเทคโนโลยีสารสนเทศอย่างครบวงจรและทันสมัยเพื่อนำส่งมูลค่าเพิ่มให้แก่ลูกค้าอย่างสูงสุด</p>
              </div>
            </div>
            <div className="py-5 mission-section">
              <h1 className='m-0 vision-title text-right'><span className='text-white vision-subtitle'>(พันธกิจ)</span> MISSION</h1>
              <p className='text-white m-0' style={{ padding: '0 3rem', fontSize: '22px', textAlign: 'right' }}>Provider of Leading Digital Solutions</p>
              <p className='text-white' style={{ padding: '0 3rem', fontSize: '22px', textAlign: 'right' }}>with The Highest Customer Value.</p>
              <div className='text-white' style={{ margin: '0 3rem 0 0', borderRight: '10px solid var(--yellow-color)', padding: '0 1rem 0 0', }}>
                <p className="m-0 text-white text-right">ดำเนินธุรกิจด้านเทคโนโลยีสารสนเทศอย่างครบวงจรและทันสมัย</p>
                <p className="m-0 text-white text-right">เพื่อนำส่งมูลค่าเพิ่มให้แก่ลูกค้าอย่างสูงสุด</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-image" style={{ backgroundImage: 'url(images/bg/bg-vision-02.jpg)' }}>
        <div className="container" style={{ paddingBottom: '20rem' }}>
          <div className="core-value-section" style={{position: "relative"}}>
          {/* <div className="core-value-section" style={{bottom: "215px",position: "relative"}}> */}
            <h1 className='pt-4 text-center vision-core-value-title'>CORE VALUE <span className='vision-subtitle'>(ค่านิยม)</span></h1>
            <div style={{ display: 'grid', gridTemplateColumns: '50% 50%'}}>
              <div style={{ display: 'grid', justifyContent: 'end', position: 'relative' }}>
                <div className='vision-bg-circle-yellow'>C</div>
                <div className='vision-line-yellow ' style={{ top: '2rem', backgroundColor: 'var(--yellow-color)', margin: '0 3rem 0 0' }}></div>
                <div className='vision-bg-circle-yellow' >D</div>
                <div className='vision-line-yellow ' style={{ top: '10rem', backgroundColor: 'var(--yellow-color)', margin: '0 3rem 0 0' }}></div>
                <div className='vision-bg-circle-yellow'>N</div>
              </div>
              <div>
                <h4 className='m-0'>Consistency</h4>
                <p className='text-dark' style={{ fontWeight: '500' }}>ความสม่ำเสมอ หรือ เสมอต้นเสมอปลาย</p>
                <h4 className='m-0'>Dignity</h4>
                <p className='text-dark' style={{ fontWeight: '500' }}>ความมีเกียรติ ศักดิ์ศรี เคารพต่อหน้าที่</p>
                <h4 className='m-0'>New Idea</h4>
                <p className='text-dark' style={{ fontWeight: '500' }}>ความคิดริเริ่มสร้างสรรค์</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSeven;