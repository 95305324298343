import React, { useRef, useState, useEffect } from "react";
import { InfoMedia, TitleWrap } from "../../components";
import { useParams } from "react-router-dom";
import useGetExecutives from '../../hooks/useGetExecutives';
import { baseUrl } from "../../services/Environment";
import moment from 'moment';
import { mapMonth } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const TeamPersonalDetailsExecutives = () => {
  const { executive, loading, getExecutiveById } = useGetExecutives();
  const { t, i18n } = useTranslation();
  // const [personalDetail, setPersonalDetail] = useState();
  const contactArea = useRef(null);
  const { id } = useParams();
  const [lang, setLang] = useState();
  const noHoldingOfficeTh = 'ไม่มีการดำรงตำแหน่ง';
  const noHoldingOfficeEn = 'No holding office';
  const positionTh = 'แห่ง';
  const positionEn = 'Position(s)';

  useEffect(() => {
    getExecutiveById(id);

    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    let targetOffset = contactArea.current.offsetTop - 200;
    window.scrollTo({
      top: targetOffset,
      behavior: "smooth",
    });
  };

  window.addEventListener('storage', () => {
    if (localStorage.getItem('cheese_lang')) {
      setLang(localStorage.getItem('cheese_lang'));
    } else {
      setLang('th');
    }
  });

  return (
    <div >
      <div className="container mb-100px">
        
        <TitleWrap className="text-center">
          <div class="d-flex justify-content-center pt-60px">
            <h1 class="title-line">{t('executive_title')}</h1>
          </div>
        </TitleWrap>
        <div className="row gx-60 gy-2">
          <div className="col-lg-5 mb-30">
            
          <div className="container-bd">
            <div className="frame-bd">
              <div className="image-stack">
                <div className="image-stack__item image-stack__item--top">
                  <img className="image-bd" src="../images/miw/board-directors/frame-profile.png" alt="" />
                </div>
                <div className="image-stack__item image-stack__item--bottom">
                  <img className="image-bd" src={`${baseUrl}${executive?.data.attributes.Profile_image.data.attributes.url}`} alt="" />
                </div>
              </div>
            </div>
          </div>

            {/* <img src={`${baseUrl}${executive?.data.attributes.Profile_image.data.attributes.url}`} alt="team-member" /> */}
          </div>
          <div className="col-lg-7 col-xl-7 align-self-center mb-30">
            <div className="team-about">
              <p className="team-name board-name">{(lang === 'th') ? `${executive?.data.attributes.Title_th} ${executive?.data.attributes.Firstname_th} ${executive?.data.attributes.Lastname_th}`
                : `${executive?.data.attributes.Title_en} ${executive?.data.attributes.Firstname_en} ${executive?.data.attributes.Lastname_en}`}</p>
              <span className="team-degi">
                {(lang === 'th' ? executive?.data.attributes.Position_th : executive?.data.attributes.Position_en)?.map((element, index) => {
                  return (index === 0) ? <span key={index}>{element.Description}</span> : <span key={index}> / {element.Description}</span>
                })}
              </span>
              <div className="row mb-4">
                <div className="col-sm-6">
                  <InfoMedia className="team-media" icon="fa fa-bd-01" title={(lang === 'th' ? 'วันที่ได้รับการแต่งตั้ง' : 'Appointment Date')} info={lang === 'th' ? moment(executive?.data.attributes.Appointment_date).add(543, 'year').format('LL') : mapMonth(moment(executive?.data.attributes.Appointment_date).format('LL'))} />
                </div>
                <div className="col-sm-6">
                  <InfoMedia className="team-media" icon="fa fa-bd-02" title={(lang === 'th' ? 'สัญชาติ' : 'Nationality')} info={lang === 'th' ? executive?.data.attributes.Nationality_th : executive?.data.attributes.Nationality_en} />
                </div>
                <div className="col-sm-6">
                  <InfoMedia className="team-media" icon="fa fa-bd-03" title={(lang === 'th' ? 'การถือหุ้นในบริษัท' : 'Company Shareholding')} info={`${executive?.data.attributes.Shareholding?.toFixed(2)}%`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gx-60 gy-3 mb-100px">
          <div className="col-lg-12">
            <table className="table-generalux">
              <tbody>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th' ? 'วุฒิการศึกษา/ประวัติการอบรม' : 'Education/Training Program')}</td>
                  <td className="td-generalux-details">
                    {
                      ((lang === 'th' ? executive?.data.attributes.Education_th : executive?.data.attributes.Education_en)?.length > 0)
                        ? (lang === 'th' ? executive?.data.attributes.Education_th : executive?.data.attributes.Education_en)?.map((element, index) => {
                          return <p key={index}>
                            <i className="far fa-circle text-facircle" /> {element.Description}
                          </p>
                        }) : <p>-</p>
                    }

                  </td>
                </tr>
                <tr>
                  <td className="td-generalux-title">{(lang === 'th' ? 'ตำแหน่งในบริษัทจดทะเบียนอื่น (ปัจจุบัน)' : 'Positions in Other Listed Companies (Present)')}</td>
                  {
                    ((lang === 'th' ? executive?.data.attributes.Position_public_company_th : executive?.data.attributes.Position_public_company_en)?.length > 0)
                      ? (lang === 'th' ? executive?.data.attributes.Position_public_company_th : executive?.data.attributes.Position_public_company_en)?.map((element, index) => {
                        return <td className="td-generalux-details" key={index}>{element.Description} </td>
                      }) : <td className="td-generalux-details">{lang === 'th' ? noHoldingOfficeTh : noHoldingOfficeEn} </td>
                  }
                </tr>
                <tr>
                  <td className="td-generalux-title">{lang === 'th' ? 'ตำแหน่งในกิจการ/หน่วยงานอื่น ที่ไม่ใช่บริษัทจดทะเบียน (ปัจจุบัน)' : 'Positions in Non-Listed Companies/ Other Positions (Present'}</td>
                  {
                    ((lang === 'th' ? executive?.data.attributes.Position_other_company_th : executive?.data.attributes.Position_other_company_en)?.length > 0)
                      ? (lang === 'th' ? executive?.data.attributes.Position_other_company_th : executive?.data.attributes.Position_other_company_en)?.map((element, index) => {
                        return <td className="td-generalux-details" key={index}>{element.Description} </td>
                      }) : <td className="td-generalux-details">{lang === 'th' ? noHoldingOfficeTh : noHoldingOfficeEn} </td>
                  }
                </tr>
                <tr>
                  <td className="td-generalux-title">{lang === 'th' ? 'ตำแหน่งในกิจการที่แข่งขัน/เกี่ยวเนื่องกับธุรกิจของบริษัทที่อาจทำให้เกิดความขัดแย้งทางผลประโยชน์' : 'Positions in Rival Companies/Connected Business that May Cause Conflicts of Interest'}</td>
                  {
                    (executive?.data.attributes.Position_competitor_company_th.length > 0) ? executive?.data.attributes.Position_competitor_company_th.map((element, index) => {
                      return <td className="td-generalux-details" key={index}>{element.Description} </td>
                    }) : <td className="td-generalux-details">{lang === 'th' ? noHoldingOfficeTh : noHoldingOfficeEn} </td>
                  }
                </tr>
                <tr>
                  <td className="td-generalux-title">{lang === 'th' ? 'ประสบการณ์ทำงาน' : 'Working Experience'}</td>
                  <td className="td-generalux-details">
                    {
                      ((lang === 'th' ? executive?.data.attributes.Experience_th : executive?.data.attributes.Experience_en)?.length > 0)
                        ? (lang === 'th' ? executive?.data.attributes.Experience_th : executive?.data.attributes.Experience_en)?.map((element, index) => {
                          return <p key={index}>{element.Description} </p>
                        }) : <td className="td-generalux-details">- </td>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPersonalDetailsExecutives;
