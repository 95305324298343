/** @format */

import React, { useState, useEffect } from "react";
import {
  HeaderSticky,
  HeaderTop,
  InfoMedia,
  Logo,
  MainMenu,
  MobileMenu,
} from "../../components";
import { useTranslation, withTranslation, Trans } from "react-i18next";

const HeaderTwo = () => {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();

  // Set default when reload page.
  useEffect(() => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }
  }, [lang]);

  // Event set to local storage.
  const handleLang = (lang) => {
    localStorage.setItem("cheese_lang", lang);
    dispatchEvent(new Event("storage"));
    i18n.changeLanguage(lang);
  };

  // Received data from storage and set value via hook.
  window.addEventListener("storage", () => {
    if (localStorage.getItem("cheese_lang")) {
      setLang(localStorage.getItem("cheese_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }
  });

  return (
    <header className="vs-header header-layout1">
      <HeaderTop>
        <div className="note_style1 d-flex justify-content-end">
          <span className="note_title">
            {" "}
            <i className="fas fa-globe me-1" />
            Language:
          </span>
          <select
            className="d-inline form-select-lang"
            value={lang}
            onChange={(event) => handleLang(event.target.value)}
          >
            <option value="th">ไทย</option>
            <option value="en">Eng</option>
          </select>
        </div>
      </HeaderTop>
      <div className="container">
        <div className="menu-top">
          <div className="row justify-content-between align-items-center gx-sm-0">
            <div className="col">
              <Logo image="/images/logo.png" />
            </div>
            <InfoMedia
              className="header-media col-auto"
              icon="fas fa-phone-alt"
              title="Call Us for information:"
              info={<a href="tel:+66253001856">+66 2530 0185-6</a>}
            />
            <InfoMedia
              className="header-media col-auto d-none d-lg-flex"
              icon="fas fa-envelope"
              title="Email"
              info={
                <a href="mailto:admin@cheesediginet.com">
                  admin@cheesediginet.com
                </a>
              }
            />
            <InfoMedia
              className="d-hidden header-media col-auto d-none d-xl-flex"
              icon="fas fa-map-marker-alt"
              title="Office Address"
              info="259 HGS, Hotland, USA"
            />
          </div>
        </div>
      </div>
      <HeaderSticky className="">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-auto">
              <MainMenu className="menu-style1 d-none d-lg-block " />
              <MobileMenu />
            </div>
            <div className="col-auto">{/* <FormFour /> */}</div>
          </div>
        </div>
      </HeaderSticky>
    </header>
  );
};

export default HeaderTwo;
