import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

// Home Pages
import HomeOne from "../pages/HomeOne";
import HomeTwo from "../pages/HomeTwo";
import HomeThree from "../pages/HomeThree";

// About Page
import About from "../pages/About";

// Blog Pages
import Blog from "../pages/Blog";
import BlogGrid from "../pages/BlogGrid";
import BlogDetails from "../pages/BlogDetails";

// Pricing Plans Page
import PricePlan from "../pages/PricePlan";

// Service Pages
import Service from "../pages/Service";
import ServiceDetails from "../pages/ServiceDetails";

// Team Pages
import Team from "../pages/Team";
import TeamDetails from "../pages/TeamDetails";

// Project Pages
import Project from "../pages/Project";
import ProjectDetails from "../pages/ProjectDetails";

// Contact Page
import Contact from "../pages/Contact";

// Not Fouond Page
import NotFound from "../pages/NotFound";

import AdvertisingSolution from "../pages/AdvertisingSolution";
import DigitalAdvertisingSolution from "../pages/DigitalAdvertisingSolution";
import BoardDirectors from "../pages/BoardDirectors";
import CompanyMilestone from "../pages/CompanyMilestone";
import ContactingBoard from "../pages/ContactingBoard";
import ContactUs from "../pages/ContactUs";
import Executives from "../pages/Executives";
import CompanyEvents from "../pages/CompanyEvents";
import GatewaySolution from "../pages/GatewaySolution";
import ITSolution from "../pages/ITSolution";
import OrganizationChart from "../pages/OrganizationChart";
import PersonalDetails from "../pages/PersonalDetails";
import SMSSolutionDetails from "../pages/SMSSolutionDetails";
import Payment from "../pages/Payment";
import Policy from "../pages/Policy";
import PublisherSolution from "../pages/PublisherSolution";
import ShareholderMeeting from "../pages/ShareholderMeeting";
import ShareholderStructure from "../pages/ShareholderStructure";
import ShareholdingDirectors from "../pages/ShareholdingDirectors";
import SignificantFinancial from "../pages/SignificantFinancial";
import SMSSolution from "../pages/SMSSolution";
import OTPService from "../pages/OTPService";
import Vision from "../pages/Vision";
import GeneralInformation from "../pages/GeneralInformation";
import OurClients from "../pages/OurClients";
import PersonalDetailsExecutives from "../pages/PersonalDetailsExecutives";
import ContactInvestor from "../pages/ContactInvestor";
import InvestorInfo from "../pages/InvestorInfo";
import FinancialStatements from "../pages/FinancialStatements";
import SustainabilityReport from "../pages/SustainabilityReport";
import Publications from "../pages/Publications";
import NewsEventsDetails from "../pages/NewsEventsDetails";

const AppRouters = () => (
  <Fragment>
    <Routes>
      <Route path="/" exact element={<HomeOne />} />
      <Route path="/home-two" exact element={<HomeTwo />} />
      <Route path="/home-three" exact element={<HomeThree />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/price-plan" exact element={<PricePlan />} />
      <Route path="/service" exact element={<Service />} />
      <Route path="/service-details" exact element={<ServiceDetails />} />
      <Route path="/project" exact element={<Project />} />
      <Route path="/project-details" exact element={<ProjectDetails />} />
      <Route path="/team" exact element={<Team />} />
      <Route path="/team-details" exact element={<TeamDetails />} />
      <Route path="/blog" exact element={<Blog />} />
      <Route path="/blog-grid" exact element={<BlogGrid />} />
      <Route path="/blog-details" exact element={<BlogDetails />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="*" element={<NotFound />} />
      {/* <Route path="/advertising-solution" exact element={<AdvertisingSolution/>} /> */}
      <Route
        path="/digital-advertising-solution"
        exact
        element={<DigitalAdvertisingSolution />}
      />
      <Route path="/board-directors" exact element={<BoardDirectors />} />
      <Route path="/company-milestone" exact element={<CompanyMilestone />} />
      <Route path="/contacting-board" exact element={<ContactingBoard />} />
      <Route path="/contact-us" exact element={<ContactUs />} />
      <Route path="/executives" exact element={<Executives />} />
      <Route path="/gateway-solution" exact element={<GatewaySolution />} />
      <Route path="/it-solution" exact element={<ITSolution />} />
      <Route path="/organization-chart" exact element={<OrganizationChart />} />
      <Route path="/personal-details/:id" exact element={<PersonalDetails />} />
      <Route
        path="/smssolution-details/:id"
        exact
        element={<SMSSolutionDetails />}
      />
      <Route
        path="/personal-details-executives/:id"
        exact
        element={<PersonalDetailsExecutives />}
      />
      <Route path="/payment" exact element={<Payment />} />
      <Route path="/policy" exact element={<Policy />} />
      <Route path="/publisher-solution" exact element={<PublisherSolution />} />
      <Route
        path="/shareholder-meeting"
        exact
        element={<ShareholderMeeting />}
      />
      <Route
        path="/shareholder-structure"
        exact
        element={<ShareholderStructure />}
      />
      <Route
        path="/shareholding-directors"
        exact
        element={<ShareholdingDirectors />}
      />
      <Route
        path="/significant-financial"
        exact
        element={<SignificantFinancial />}
      />
      <Route path="/sms-solution/:id" exact element={<SMSSolution />} />
      <Route path="/sms-solution" exact element={<SMSSolution />} />
      <Route path="/otp-service" exact element={<OTPService />} />
      <Route path="/vision" exact element={<Vision />} />
      <Route
        path="/general-information"
        exact
        element={<GeneralInformation />}
      />
      <Route path="/our-clients" exact element={<OurClients />} />
      <Route path="/contact-investor" exact element={<ContactInvestor />} />
      <Route path="/investor-info" exact element={<InvestorInfo />} />
      <Route
        path="/financial-statements"
        exact
        element={<FinancialStatements />}
      />
      <Route path="/publications" exact element={<Publications />} />
      <Route
        path="/sustainability-report"
        exact
        element={<SustainabilityReport />}
      />
      <Route path="/company-events" exact element={<CompanyEvents />} />{" "}
      {/*NameNuT 20240422*/}
      <Route
        path="/news-events-details/:id"
        exact
        element={<NewsEventsDetails />}
      />{" "}
      {/*NameNuT 20240423*/}
    </Routes>
  </Fragment>
);

export default AppRouters;
