import React, {Fragment} from "react";
import {Seo, ScrollTopBtn, Breadcrumb} from "../components";
import {HeaderTwo, AboutFinancialStatements, TeamFinancialStatements, FooterTwo} from "../containers";

const FinancialStatements = () => (
  <Fragment>
    <Seo title="Financial Statements" />
    <HeaderTwo />
    <Breadcrumb pageName="Financial Statements" bgImage="images/miw/bar_013.jpg" />
    <TeamFinancialStatements />
    <FooterTwo />
    <ScrollTopBtn />
  </Fragment>
);

export default FinancialStatements;
